export default {
  technology: 'Technology',
  solutions: 'Solutions',
  caseStudies: 'Case Studies',
  pricing: 'Pricing',
  learn: 'Learn',
  company: 'Company',
  login: 'Вход',
  signUp: 'Pегистрация',
  signIn: 'Вход',
  continue: 'Перейти в Corezoid',

  // header
  headerProduct: 'Продукты',
  headerSolutions: 'Решения',
  headerProductProcessEngine: 'Process Engine',
  headerProductBotPlatform: 'Communications Orchestrator',
  headerSolutionsSoftware: 'ПО',
  headerSolutionsApi: 'API',
  headerSolutionsCode: 'Код',
  headerSolutionsProcesses: 'Процессы',
  headerSolutionsIot: 'IoT',
  headerSolutionsCommunication: 'Коммуникация',
  headerSolutionsEvents: 'События',
  headerSolutionsKnowledge: 'Знания',
  headerSolutionsPeople: 'Люди',
  headerSolutionsCloud: 'Облака',
  headerCaseStudies: 'Кейсы',
  headerPricing: 'Прайсинг',
  headerLearn: 'Обучение',
  headerCompany: 'Компания',
  headerCompanyAbout: 'О нас',
  headerCompanyNews: 'Новости',
  headerCompanyPartnership: 'Партнерство',
  headerCompanyJobs: 'Вакансии',

  // news Header
  newsSigmaSoftware: 'Открытый онлайн вебинар: «Цифровая трансформация для банков: тенденции и проблемы». Модератор: Сергей Даниленко, вице-президент Middleware.',

  // 404
  '404Title': 'OOPS: PAGE NOT FOUND',
  '404SubTitle': 'WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND',

  // main Page
  // mainTitle: 'Хочешь стать цифровым магнатом?',
  mainTitle: 'Как начать цифровую трансформацию компании?',
  mainSubTitle: 'Путь к цифровой трансформации начинается с первого процесса...',
  mainBtnOnline: 'Попробовать облачную версию',
  // mainBtnDownload: 'Запросить демо',
  mainBtnDownload: 'Запросить демо продукта',
  // mainBtnDownload: 'Запустить на вашем сервере',
  mainDemoTitle: 'Создайте свое цифровое ядро прямо сейчас!',
  // mainDemoBtnCreate: 'Создать первый процесс',
  // mainDemoBtnDemo: 'Запросить демо',
  mainContentSoftwareTitle: 'Управляйте “зоопарком” ПО от разных поставщиков',
  mainContentSoftwareSubTitle: 'В каждой компании есть десятки ПО от разных поставщиков. Corezoid поможет объединить этот “зоопарк” ПО в единую экосистему и эффективно управлять им.',
  mainContentSoftwareBtnTitle: 'Подробнее',
  mainContentApiTitle: 'Управляйте внешними и создавайте свои API',
  mainContentApiSubTitle: 'В мире миллионы API, и каждый имеет свой уникальный протокол. Corezoid - это универсальный метаязык, который позволяет быстро создавать новые и управлять существующими API в едином интерфейсе.',
  mainContentApiBtnTitle: 'Подробнее',
  mainContentCodeTitle: 'Используйте код всего мира на любом языке программирования',
  mainContentCodeSubTitle: 'Зачем изобретать велосипед? Почти весь код уже написан. Corezoid позволяет найти нужный код на любом git-репозитории и запустить его как часть вашего продукта.',
  mainContentCodeBtnTitle: 'Подробнее',
  mainContentProcessesTitle: 'Фокусируйтесь на процессах, а не их программировании',
  mainContentProcessesSubTitle: 'Переносите бизнес-логику из кода и устаревшего ПО в процессы Corezoid, чтобы ускорить создание новых продуктов и снизить расходы на поддержку.',
  mainContentProcessesBtnTitle: 'Подробнее',
  mainContentIotTitle: 'Управляйте “зоопарком” IoT',
  mainContentIotSubTitle: 'В мире сотни тысяч девайсов от разных поставщиков, и каждый имеет свой протокол. Corezoid поможет объединить этот “железный зоопарк” в единую экосистему и эффективно управлять им.',
  mainContentIotBtnTitle: 'Подробнее',
  mainContentCommunicationTitle: 'Управляйте и автоматизируйте коммуникации',
  mainContentCommunicationSubTitle: 'Клиенты хотят общаться с бизнесом через десятки каналов коммуникации. Corezoid поможет управлять всеми каналами коммуникации и автоматизировать общение с помощью роботов.',
  mainContentCommunicationBtnTitle: 'Подробнее',
  mainContentEventsTitle: 'Держи все под контролем!',
  mainContentEventsSubTitle: 'Отсутствие контроля - это одна из ключевых проблем всех компаний. Control.events поможет эффективно отслеживать и управлять событиями, которые происходят как внутри, так и снаружи компании. Контролируйте любые типы событий: проекты и задачи внутри компании, встречи в календарях, статусы контрактов с партнерами и контрагентами и многое другое. Благодаря Corezoid вы можете интегрировать Control.events с неограниченным количеством источников событий.',
  mainContentEventsBtnTitle: 'Подробнее',
  mainContentKnowledgeTitle: 'Управляйте информацией и знаниями компании',
  mainContentKnowledgeSubTitle: 'Информация и знания компании скрыты в данных и хранятся в десятках разрозненных ПО в разных форматах. Облачная экспертная система DeepMemo поможет объединить данные из разных источников в едином хранилище и извлечь из сырых данных информацию и знания для принятия более качественных и эффективных решений: от супер персонализации до антифрода.',
  mainContentKnowledgeBtnTitle: 'Подробнее',
  mainContentPeopleTitle: 'Управляйте лидами, клиентами, сотрудниками, партнерами, поставщиками...',
  mainContentPeopleSubTitle: 'Перенесите технологии управления людьми в Corezoid: создавайте воронки продаж, управляйте жизненным циклом клиентов, поставщиками и складскими запасами; отслеживайте выполнение и отсутствие выполнения KPI и задач сотрудников.',
  mainContentPeopleBtnTitle: 'Подробнее',
  mainContentCloudTitle: 'Облачные вычисления без облаков',
  mainContentCloudSubTitle: '«Банкинг нужен, а вот банки – нет» (с) Бил Гейтс[br][br] Признайтесь, вам нужны результаты вычислений, а не то как они происходят. Создавайте и запускайте алгоритмы любой сложности в автомасштабируемом и безопасном облаке Corezoid.',
  mainContentCloudBtnTitle: 'Подробнее',
  mainContentDemoBtnTitle: 'Подробнее',
  mainContentCoreTitle: 'Создайте свое цифровое ядро прямо сейчас!',
  mainContentCoreBtnTitle: 'Начать',

  // benefits
  benefitsTitle: 'Преимущества Corezoid',
  benefitsSubTitle: 'Узнайте больше о выгодах Corezoid для всей команды',
  benefitsManagers: 'Менеджеры',
  benefitsDevelopers: 'Разработчики',

  // product
  productHeaderTitle: 'Анатомия Corezoid Actor Engine',
  productHeaderSubTitle: 'Узнайте больше о возможностях Corezoid',
  productAssembleTitle: 'Создавайте',
  productAssembleSubTitle: 'Редактор процессов',
  productAssembleListEditTitle: 'Режим редактирования',
  productAssembleListEditText: 'Легко создавайте процессы с помощью интуитивного drag&drop редактора',
  productAssembleListViewTitle: 'Режим просмотра',
  productAssembleListViewText: 'Контролируйте исполнение ваших процессов в режиме реального времени',
  productAssembleListDebugTitle: 'Режим отладки (Debug)',
  productAssembleListDebugText: 'Отслеживайте исполнение каждого шага процесса, чтобы оперативно находить и исправлять ошибки',
  productAssembleConsHostTitle: 'Хостинг процессов',
  productAssembleConsHostText: 'Corezoid позволяет хостить процессы всего в 1 клик как нашем публичном облаке, так и вашем приватном окружении[br][br][iurl=/ru/pricing]Узнать больше о типах хостинга Corezoid[/iurl]',
  here: 'Посмотреть возможности хостинга',
  productAssembleConsExecutionTitle: 'Исполнение процессов',
  productAssembleConsExecutionText: 'Запускайте, изменяйте и отслеживайте исполнение процессов в режиме реального времени, без необходимости перезагрузки системы',
  productOrganizeTitle: 'Структурируйте',
  productOrganizeSubTitle: 'Браузер процессов',
  productOrganizeListProjectsTitle: 'Проекты',
  productOrganizeListProjectsText: 'Изолированное окружение помогает управлять процессом разработки проекта',
  productOrganizeListComponentsTitle: 'Компоненты',
  productOrganizeListComponentsText: 'Разрабатывайте и обновляйте каждый компонент отдельно',
  productOrganizeListProcessesTitle: 'Процессы',
  productOrganizeListProcessesText: 'Каждый компонент состоит из набора независимых процессов',
  productShareTitle: 'Делитесь',
  productShareSubTitle: 'Пользователи и группы',
  productShareListUsersTitle: 'Пользователи',
  productShareListUsersText: 'Делитесь доступом к проектам, компонентам и процессам с определенными пользователями',
  productShareListGroupsTitle: 'Группы',
  productShareListGroupsText: 'Создавайте группы пользователей для управления корпоративными правами доступа',
  productShareListApiTitle: 'Ключи API',
  productShareListApiText: 'Предоставляйте доступ к вашим проектам внешним разработчикам, клиентам, партнерам',
  productControlTitle: 'Контролируйте',
  productControlSubTitle: 'Права доступа',
  productControlListViewTitle: 'Просмотр',
  productControlListViewText: 'Просмотр структуры проектов и наблюдение за исполнением процессов',
  productControlListModifyTitle: 'Изменение',
  productControlListModifyText: 'Создание и управление процессами и проектами',
  productControlListTaskTitle: 'Управление задачами',
  productControlListTaskText: 'Создание, обновление и удаление задач в процессах',
  productAdditionsListCollaborationTitle: 'Совместная работа',
  productAdditionsListCollaborationText: 'Подключайте коллег, партнеров и подрядчиков к совместной параллельной работе над проектами для раскрытия потенциала коллективного творчества и опыта',
  productAdditionsListTransparencyTitle: 'Прозрачность',
  productAdditionsListTransparencyText: 'Corezoid позволяет собрать все процессы компании в одном месте, благодаря чему каждый член команды может получить доступ и управлять любыми процессами в рамках выданных полномочий',
  productAnalizeTitle: 'Анализируйте',
  productAnalizeSubTitle: 'Дашборды в режиме реального времени',
  productAnalizeListCustomizableTitle: 'Кастомизируемые дашборды',
  productAnalizeListCustomizableText: 'Благодаря полностью настраиваемым дашбордам, которые отображаются информацию в режиме реального времени, вы можете держать руку на пульсе вашего бизнеса и оперативно принимать управленческие решения',
  productAnalizeListActivityTitle: 'Управление ресурсами',
  productAnalizeListActivityText: 'Анализируйте сколько задач, тактов и трафика потребляют ваши процессы, чтобы прогнозировать и оптимизировать свои расходы',
  productDemoTitle: 'Создайте свой первый процесс прямо сейчас!',
  productDemoButton: 'НАЧАТЬ',

  // lending
  registration: 'Начать',
  registrationSubText: 'Бесплатный 30-дневный пробный период',
  orderWorkshops: 'Заказать воркшоп',
  orderDemo: 'Запросить демо',
  startFreeDemo: 'НАЧАТЬ',

  // bot-platform
  botPlatformTitle: 'Communications Orchestrator ',
  botPlatformSubTitle: 'Создавайте, управляйте и хостите Ваших мессенджер-ботов без навыков программирования в едином интерфейсе Corezoid.',
  botPlatformMessengerTitle: 'Все популярные мессенджеры и каналы коммуникации в одном месте',
  botPlatformMessengerSubTitle: 'Клиенты хотят общаться с Вашим бизнесом через мессенджеры. Начните коммуницировать с Вашими клиентами через все популярные мессенджеры с помощью Communications Orchestrator от Corezoid прямо сейчас!',
  botPlatformLogicTitle: 'Одна бизнес-логика для всех мессенджеров',
  botPlatformLogicSubTitle: 'Надоело создавать бизнес-логику для каждого мессенджера отдельно? Теперь вы можете быстро создавать универсальные алгоритмы, которые работают во всех мессенджерах сразу.',
  botPlatformBotTitle: 'Автоматизация общения с помощью ботов',
  botPlatformBotSubTitle: 'От 50% до 90% вопросов клиентов - типичные. А значит, их можно автоматизировать. Автоматизируйте общение с клиентами вместе с Communications Orchestrator . Это позволит увеличить эффективность коммуникации и сократить расходы на содержание персонала.',
  botPlatformContactCenterTitle: 'Омни-канальный контакт-центр',
  botPlatformContactCenterSubTitle: 'Благодаря нативной интеграции Communications Orchestrator  с Sender, Вы получаете рабочее место оператора и сразу сможете подключить операторов для консультации клиентов во всех популярных мессенджерах.',
  botPlatformIntegrationTitle: 'Неограниченные возможности интеграции',
  botPlatformIntegrationSubTitle: 'Расширяйте функциональность Ваших ботов благодаря неограниченным возможностям интеграции с внешними сервисами. Единственное условие - наличие API.',
  botPlatformAnalyticTitle: 'Аналитика (дашборды в реальном времени)',
  botPlatformAnalyticSubTitle: 'С Communications Orchestrator Вы можете самостоятельно создавать аналитические онлайн-дашборды и выбирать ключевые метрики, которые Вам необходимо отслеживать. ',
  botPlatformEnterpriseTitle: 'Возможности для Enterprise',
  botPlatformEnterpriseSecurityTitle: 'Безопасность',
  botPlatformEnterpriseSecuritySubTitle: 'Corezoid был рожден в банковской отрасли, где вопрос безопасности - критически важен. Corezoid успешно прошел ряд аудитов от таких компаний как Avon, WU, METRO, AWS и соответствует требованиям PCI DSS и ISO27001. Также Corezoid является рекомендованным решением для финансовой отрасли в рамках программы AWS Financial Services Competency.',
  botPlatformEnterprisePerformanceTitle: 'Производительность',
  botPlatformEnterprisePerformanceSubTitle: 'Corezoid позволяет создавать сервисы, которые без проблем выдерживают нагрузку в сотни тысяч запросов в секунду. В работе с ведущими мировыми компаниями мы зарекомендовали себя как надежное решения для высоконагруженных систем.',
  botPlatformEnterpriseScaleTitle: 'Масштабируемость',
  botPlatformEnterpriseScaleSubTitle: 'Corezoid обеспечивает нативное автоматическое горизонтальное масштабирование, что позволит вам не содержать штат devops и разработчиков для решения подобных вопросов. Концентрируйтесь на бизнесе, а не проблемах масштабирования.',
  botPlatformBotsTitle: 'Посмотрите как клиенты используют Communications Orchestrator ',
  botPlatformStartTitle: 'Начните пользоваться Communications Orchestrator  уже сегодня',
  botPlatformSignIn: 'Зарегистрируйтесь на Corezoid.com',
  botPlatformTutorial: 'Пройдите туториал Communications Orchestrator ',
  botPlatformStartBots: 'Начните строить ботов без программистов',

  // Apple Messages for Business
  abcTitle: 'Apple Messages for Business',
  abcSubTitle: 'Совершенно новый способ общения с компаниями',
  abcText: 'Теперь вы можете запрашивать информацию, планировать встречи и даже совершать покупки прямо в приложении Messages на вашем iPhone или iPad, что делает связь с вашими любимыми компаниями такой же простой, как отправка сообщений вашим друзьям',
  abcButtonText: 'Начать сейчас',
  abcFeaturesTitle: 'Особенности Apple Messages for Business',
  abcFeaturesBuiltInMessagesTitle: 'Встроенное приложение Messages',
  abcFeaturesBuiltInMessagesText: 'Приложение Apple Messages встроено в iOS, а значит пользователям iPhone и iPad не потребуется загружать и устанавливать сторонние приложения для использования Apple Messages for Business.',
  abcFeaturesPayTitle: 'Оплата через Apple Pay',
  abcFeaturesPayText: 'Пользователи могут оплачивать Ваши товары и услуги прямо из Apple Messages for Business, без необходимости переходить на внешние сайты, вводить карточные данные и т.д.',
  abcFeaturesOperatorTitle: 'Подключение оператора',
  abcFeaturesOperatorText: 'С помощью нативной интеграции Communications Orchestrator и Sender, Вы можете мгновенно подключить операторов для обслуживания клиентов из Apple Messages for Business.',
  abcFeaturesMediaTitle: 'Rich media: картинки и видео',
  abcFeaturesMediaText: 'Повышайте вовлеченность клиентов в общение с Вашей компанией благодаря возможности отправлять клиентам сообщения с картинками и видео.',
  abcFeaturesAppointmentsTitle: 'Планируйте встречи',
  abcFeaturesAppointmentsText: 'Встроенная в Apple Messages for Business функция выбора даты и времени позволит предоставить Вашим клиентам удобный инструмент записи на встречу, прием или мероприятие.',
  abcFeaturesExtensionCallTitle: 'Приложение Extension Call',
  abcFeaturesExtensionCallText: 'Если перечисленных выше функций недостаточно, Вы всегда можете вызвать любую функцию из Вашего iOS приложения, чтобы расширить возможности Apple Messages for Business.',
  abcEnterEmailPlaceholder: 'Введите свой email',
  abcEnterEmailButton: 'Подписаться',
  abcFormTitle: 'Начните использовать Apple Messages for Business уже сегодня',

  // botPlatform viber
  botPlatformViberTitle: 'Начните обслуживать клиентов в самом популярном мессенджере',
  botPlatformViberSubTitle: 'Благодаря возможностям Viber, Вы сможете не только общаться с вашими клиентами, но и начать продавать товары и услуги в мессенджере.',
  botPlatformViberButtonText: 'Начать сейчас',
  botPlatformViberKeyboardTitle: 'Клавиатуры',
  botPlatformViberKeyboardSubTitle: 'Создайте набор своих уникальных клавиатур, которые позволят пользователям управлять вашим ботом',
  botPlatformViberSliderTitle: 'Карусели',
  botPlatformViberSliderSubTitle: 'Ваши клиенты смогут просматривать каталог товаров и услуг с помощью встроенных настраиваемых каруселей',
  botPlatformViberContactCenterTitle: 'Контакт-центр',
  botPlatformViberContactCenterSubTitle: 'С помощью нативной интеграции Communications Orchestrator и Sender, Вы можете мгновенно подключить операторов для обслуживания клиентов из Viber',
  botPlatformViberBulkMailingTitle: 'Массовые рассылки',
  botPlatformViberBulkMailingSubTitle: 'Отправляйте массовые рассылки с помощью встроенного в Communications Orchestrator модуля рассылок',
  botPlatformViberSharingPhoneTitle: 'Шейринг номера телефона',
  botPlatformViberSharingPhoneSubTitle: 'Авторизуйте ваших пользователей по номеру телефона без SMS-верификации',
  botPlatformViberSharingLocationTitle: 'Шейринг локации',
  botPlatformViberSharingLocationSubTitle: 'Запрашивайте локацию пользователя для создания услуг, связанных с геопозицией',
  botPlatformViberRichMediaTitle: 'Rich Media',
  botPlatformViberRichMediaSubTitle: 'Обменивайтесь с вашими клиентами любыми форматами файлов: аудио, видео, картинки',
  botPlatformViberStikersTitle: 'Стикеры',
  botPlatformViberStikersSubTitle: 'Говорите с вашими клиентам на одном языке с помощью отправки стикеров Viber',
  botPlatformViberDeepLinkTitle: 'Deep Link',
  botPlatformViberDeepLinkSubTitle: 'Создавайте динамические ссылки, которые могут передавать контекст коммуникации, данные и даже запускать функции бота',
  botPlatformViberDeepLinkLink: 'Заказать доставку еды',
  botPlatformViberPaymentsTitle: 'Платежи',
  botPlatformViberPaymentsSubTitle: 'Пользователи могут мгновенно оплачивать свои покупки при помощи Apple/Google Pay не покидая чат мессенджера',
  botPlatformViberClientsTitle: 'Эти клиенты уже используют Viber для своего бизнеса',
  botPlatformViberFormTitle: 'Начните использовать Viber для бизнеса уже сегодня',

  // botPlatform google business messages
  botPlatformGMBTitle: 'Google Business Messages',
  botPlatformGMBSubTitle: 'Откройте новый канал продаж с Google Business Messages: демонстрируйте свои товары и услуги при помощи каруселей, продавайте, оказывайте операторскую поддержку, автоматизируйте обслуживание при помощи ботов',
  botPlatformGMBPointsTitle: 'Объедините точки входа',
  botPlatformGMBPointsSubTitle: 'Начните общаться с клиентами, которые пришли из Google Maps, из выдачи поиска, веб-сайта, мобильного приложения и даже оффлайн',
  botPlatformGMBserviceTitle: 'Персонализированное обслуживание',
  botPlatformGMBserviceSubTitle: 'Персонализируйте обслуживание клиентов в зависимости от точки входа',
  botPlatformGMBkeyboardsTitle: 'Клавиатуры',
  botPlatformGMBkeyboardsSubTitle: 'Сделайте взаимодействие клиентов с ботом максимально удобным при помощи настраиваемых каруселей',
  botPlatformGMBCarouselsTitle: 'Карусели',
  botPlatformGMBCarouselsSubTitle: 'Демонстрируйте клиентам свои товары и услуги при помощи настраиваемых каруселей',
  botPlatformGMBCommunicationTitle: 'Коммуникационный центр',
  botPlatformGMBCommunicationSubTitle: 'Используйте встроенный функционал Communications Orchestrator, чтобы подключать операторов для решения клиентских вопросов в чате',
  botPlatformGMBRichCardTitle: 'Rich Card',
  botPlatformGMBRichCardSubTitle: 'Отправляйте квитанции, электронные билет или ваучеры прямо в чат клиенту при помощи изображений или QR кодов',
  botPlatformGMBFormTitle: 'Начните использовать Google Business Messages уже сегодня',

  // botPlatform telegram
  botPlatformTelegramTitle: 'Будьте ближе к своим клиентам',
  botPlatformTelegramSubTitle: 'Благодаря Telegram Вы можете не только общаться с клиентами в чате, но и создавать ботов для автоматизации коммуникации и продажи Ваших товаров и услуг',
  botPlatformTelegramButtonText: 'Начать сейчас',
  botPlatformTelegramKeyboardTitle: 'Классическая клавиатура',
  botPlatformTelegramKeyboardSubTitle: 'Создавайте собственные клавиатуры, которые позволят клиентам управлять Вашим ботом',
  botPlatformTelegramInlineKeyboardTitle: 'Inline-клавиатура',
  botPlatformTelegramInlineKeyboardSubTitle: 'Создавайте динамические клавиатуры и добавляйте их к сообщениям для расширения возможностей Вашего бота',
  botPlatformTelegramContactCenterTitle: 'Контакт-центр',
  botPlatformTelegramContactCenterSubTitle: 'С помощью нативной интеграции Communications Orchestrator и Sender, Вы можете мгновенно подключить операторов для обслуживания клиентов из Telegram',
  botPlatformTelegramBulkMailingTitle: 'Массовые рассылки',
  botPlatformTelegramBulkMailingSubTitle: 'Отправляйте новости о своих услугах или создавайте тематические рассылки с помощью встроенного в Communications Orchestrator модуля рассылок',
  botPlatformTelegramPayTitle: 'Платежи',
  botPlatformTelegramPaySubTitle: 'Пользователи могут всего в несколько кликов оплачивать товары и услуги с помощью Apple/Android Pay, без необходимости ввода платежных реквизитов',
  botPlatformTelegramSharingPhoneTitle: 'Шейринг номера телефона',
  botPlatformTelegramSharingPhoneSubTitle: 'Авторизовывайте ваших пользователей по номеру телефона без необходимости проведения SMS-верификации',
  botPlatformTelegramSharingLocationTitle: 'Шейринг локации',
  botPlatformTelegramSharingLocationSubTitle: 'Получайте локацию пользователя для предоставления услуг связанных с местоположением',
  botPlatformTelegramRichMediaTitle: 'Rich Media',
  botPlatformTelegramRichMediaSubTitle: 'Обменивайтесь с вашими клиентами любыми форматами файлов: аудио, видео, картинки',
  botPlatformTelegramDeepLinkTitle: 'Deep Link',
  botPlatformTelegramDeepLinkSubTitle: 'Передавайте контекст коммуникации, данные и даже запускайте скрытые функции бота, при помощи динамических ссылок',
  botPlatformTelegramDeepLinkLink: 'Заказать доставку еды',
  botPlatformTelegramClientsTitle: 'Эти клиенты уже используют Telegram для своего бизнеса',
  botPlatformTelegramFormTitle: 'Начните использовать Telegram для бизнеса уже сегодня',

  // botPlatform facebook
  botPlatformFacebookTitle: 'Откройте новый канал продаж и обслуживания клиентов с Facebook Messenger',
  botPlatformFacebookSubTitle: 'Начните обслуживать клиентов в Facebook Messenger с помощью операторов; автоматизируйте поддержку клиентов c помощью роботов; продавайте товары и услуги, с помощью каталогов товаров и инвойсов',
  botPlatformFacebookButtonText: 'Начать сейчас',
  botPlatformFacebookKeyboardTitle: 'Клавиатуры',
  botPlatformFacebookKeyboardSubTitle: 'Настройте удобную клавиатуру для взаимодействия клиентов с вашим ботом',
  botPlatformFacebookTemplatesTitle: 'Шаблоны',
  botPlatformFacebookTemplatesSubTitle: 'Используйте набор встроенных шаблонов сообщений для продажи товаров и услуг, отправки документов, авиабилетов, чеков, новостей или персонализированных предложений',
  botPlatformFacebookContactCenterTitle: 'Контакт-центр',
  botPlatformFacebookContactCenterSubTitle: 'По умолчанию вы получаете встроенный операторский чат, который позволит обрабатывать клиентские обращения из Facebook Messenger',
  botPlatformFacebookProfileTitle: 'Доступ к Facebook-профилю клиента',
  botPlatformFacebookProfileSubTitle: 'Получайте данные из профиля клиента для персонализации коммуникации и улучшения качества обслуживания',
  botPlatformFacebookDeepLinkTitle: 'Deep Link',
  botPlatformFacebookDeepLinkSubTitle: 'Запускайте функции бота или передавайте контекст коммуникации при помощи динамических ссылок',
  botPlatformFacebookDeepLinkLink: 'Заказать доставку по акции',
  botPlatformFacebookRichMediaTitle: 'Rich Media',
  botPlatformFacebookRichMediaSubTitle: 'Предоставьте клиенту ответ в удобном формате в зависимости от контекста коммуникации: аудио, видео, картинки или файлы',
  botPlatformFacebookClientsTitle: 'Эти клиенты уже используют  Facebook Messenger  для своего бизнеса',
  botPlatformFacebookFormTitle: 'Начните использовать Facebook Messenger  для бизнеса уже сегодня',

  // software
  softwareHeaderTitle: 'Управляйте “зоопарком” ПО от разных поставщиков',
  softwareHeaderSubTitle: 'В каждой компании есть десятки ПО от разных поставщиков. Corezoid поможет объединить этот “зоопарк” ПО в единую экосистему и эффективно управлять им.',
  softwareContentZooTitle: 'Объедините зоопарк ПО в едином интерфейсе',
  softwareContentZooSubTitle: 'Биллинг, календарь, бухгалтерия, мобильные приложения, мессенджеры, роботы… этот список можно продолжать. Все компании используют больше 1 ПО для ведения бизнеса. Corezoid поможет организовать взаимодействие между разными ПО и сконцентрироваться на бизнесе, а не проблемах интеграции.',
  softwareContentSoftTitle: 'Расширяйте возможности вашего ПО',
  softwareContentSoftSubTitle: 'ПО развивается медленнее, чем бизнес. Corezoid позволит не попасть в заложники ситуации, когда ваш бизнес не может развиваться из-за ограниченных возможностей ПО. Быстро создавайте недостающие функции с помощью Corezoid.',
  softwareContentDashTitle: 'Управляйте ПО с помощью онлайн-дашбордов',
  softwareContentDashSubTitle: 'Контролируйте и оптимизируйте ваши процессы по управлению ПО благодаря встроенным онлайн-дашбордам Corezoid, которые позволяют собирать и отслеживать статистику из любых точек процесса в режиме реального времени.',
  softwareContentEscalTitle: 'Управляйте событиями и настраивайте эскалации',
  softwareContentEscalSubTitle: 'Corezoid поможет организовать управление потоком событий от ваших ПО и настроить эскалации в режиме реального времени.',
  softwareContentTimeTitle: 'Простота поддержки и внесения изменений',
  softwareContentTimeSubTitle: 'Благодаря низкому порогу входа и интуитивно понятному интерфейсу, Corezoid позволяет создавать, изменять и поддерживать логику процессов по управлению ПО без хардкодинга.',

  // api
  apiHeaderTitle: 'Управляйте внешними и создавайте свои API',
  apiHeaderSubTitle: 'В мире миллионы API, и каждый имеет свой уникальный протокол. Corezoid - это универсальный метаязык, который позволяет быстро создавать новые и управлять существующими API в едином интерфейсе.',
  apiContentSingleTitle: 'Единый интерфейс и протокол для управления любыми API',
  apiContentSingleSubTitle: 'Сегодня продукты состоят из десятков и сотен внешних и внутренних API. Corezoid поможет создать универсальный протокол для общения между разными API и объединить управление API в одном интерфейсе.',
  apiContentAutoTitle: 'Автоматически создавайте собственные API',
  apiContentAutoSubTitle: 'Каждый созданный в Corezoid процесс является API, которое можно использовать как часть функциональности вашего продукта. API в Corezoid могут работать как в синхронном, так и асинхронном режиме.',
  apiContentWebhooksTitle: 'Webhooks',
  apiContentWebhooksSubTitle: 'Используйте процессы Corezoid для получения внешних событий и настройки триггеров, по которым события будут маршрутизироваться и обрабатываться в ваших процессам, ПО или внешних и внутренних API.',
  apiContentPerformanceTitle: 'Производительность',
  apiContentPerformanceSubTitle: 'Благодаря отказоустойчивой архитектуре и автоматическому горизонтальному масштабированию, Corezoid позволяет обрабатывать десятки тысяч запросов в секунду.',
  apiContentSecurityTitle: 'Безопасность',
  apiContentSecuritySubTitle: 'Встроенная система управления правами доступа, поможет организовать безопасное взаимодействие ваших процессов с внешними и внутренними ПО, API и сервисами.',

  // processes
  processesHeaderTitle: 'Фокусируйтесь на процессах, а не их программировании',
  processesHeaderSubTitle: 'Переносите бизнес-логику из кода и устаревшего ПО в процессы Corezoid, чтобы ускорить создание новых продуктов и снизить расходы на поддержку.',
  processesHeaderButton: 'Запросить демо',
  processesContentTimeTitle: 'Сократите time-to-market до 0',
  processesContentTimeSubTitle: 'Разработка продуктов в рамках классической парадигмы программирования и с устаревшим ПО требует слишком много ресурсов и несет огромные риски. Corezoid поможет увеличить скорость итераций, снизить стоимость и риски за счет привлечения существенно меньшего кол-во людей для реализации проектов и принципиального иному подходу к разработке.',
  processesContentDashTitle: 'Real-time Dashboards',
  processesContentDashSubTitle: 'Благодаря встроенным онлайн-дашбордам, вы сможете в режиме реального времени отслеживать поведение любой точки ваших процессов и принимать управленческие решения для повышению эффективности и оптимизации бизнеса.',
  processesContentTestTitle: 'Сделай A/B-тесты нормой',
  processesContentTestSubTitle: 'С помощью Corezoid, вы сможете создавать, тестировать и улучшать десятки версий ваших процессов за считанные часы, без необходимости привлечения разработчиков.',
  processesContentProtoTitle: 'Инструмент для прототипирования',
  processesContentProtoSubTitle: 'Благодаря низкому порогу входа, Corezoid позволяет быстро создавать прототипы ваших продуктов, запускать их на рынок и получать обратную связь.',

  // code
  codeHeaderTitle: 'Используйте код всего мира на любом языке программирования',
  codeHeaderSubTitle: 'Зачем изобретать велосипед? Почти весь код уже написан. Corezoid позволяет найти нужный код на любом git-репозитории и запустить его как часть вашего продукта.',
  codeContentRepoTitle: 'Запускай любой код из публичных и приватных репозиториев',
  codeContentRepoSubTitle: '80% кода уже написано. Найди нужный и используй его. Запускайте любой код из GIT с помощью Corezoid всего в несколько кликов.',
  codeContentProcTitle: 'Код из репозитория как часть процесса',
  codeContentProcSubTitle: 'Вашему процессу не хватает функциональности? Просто добавьте узел GIT Call и вы сможете использовать код из репозитория как часть вашего процесса.',
  codeContentLangTitle: 'Запускайте код на всех языках программирования',
  codeContentLangSubTitle: 'Corezoid поддерживает все популярные языки программирования: JS, Java, Go, Python, C++, C#...',
  codeContentHostTitle: 'Хостинг без хостинга',
  codeContentHostSubTitle: 'Нашли код на GIT, но как его использовать? Просто укажите путь к репозиторию, добавьте команды для сборки и Corezoid моментально запустит ваш код.',
  codeContentScaleTitle: 'Нативное горизонтальное масштабирование',
  codeContentScaleSubTitle: 'Вам больше не нужно думать о масштабировании - Corezoid все сделает за вас. При увеличении нагрузки мы автоматически увеличиваем capacity серверов и позволяем масштабироваться в рамках лимитов выбранного тарифного плана.',

  // iot
  iotHeaderTitle: 'Управляйте “зоопарком” IoT',
  iotHeaderSubTitle: 'В мире сотни тысяч девайсов от разных поставщиков, и каждый имеет свой протокол. Corezoid поможет объединить этот “железный зоопарк” в единую экосистему и эффективно управлять им.',
  iotContentIronParkTitle: 'Объедините “железный зоопарк” в единую экосистему',
  iotContentIronParkSubTitle: 'Количество IoT-девайсов растет с каждым днем и каждый производитель IoT имеет свой протокол. Corezoid поможет создать унифицированный протокол и шину общения между девайсами и объединить их в единую экосистему.',
  iotContentEventTitle: 'Управляйте событиями в режиме реального времени (Event Stream Processing)',
  iotContentEventSubTitle: 'Corezoid поможет управлять потоками событий от ваших девайсов, позволяя гибко настраивать логику агрегации, маршрутизации и обработки событий в режиме реального времени.',
  iotContentEscalTitle: 'Настраивайте автоматические эскалации',
  iotContentEscalSubTitle: 'Хотите получать уведомления о результатах активности или аномальных явлениях? Corezoid легко позволяет настраивать мониторинг, обработку и уведомления о непредвиденных ситуациях: всплесках повышения нагрузки, сбоях работы девайсов, отказах внешних и внутренних сервисов и других аномальных явлениях.',
  iotContentOnlineTitle: 'Дашборды в режиме реального времени',
  iotContentOnlineSubTitle: 'Corezoid позволяет автоматически отслеживать любые метрики ваших IoT-девайсов и отображать статистику  в режиме реального времени с помощью встроенных онлайн-дашбордов.',
  iotContentPerformanceTitle: 'Производительность',
  iotContentPerformanceSubTitle: 'Благодаря высокой отказоустойчивости и автоматическому горизонтальному масштабированию, Corezoid позволяет без проблем обрабатывать сотни тысяч событий в секунду.',

  // communication
  communicationHeaderTitle: 'Управляйте и автоматизируйте коммуникации',
  communicationHeaderSubTitle: 'Клиенты хотят общаться с бизнесом через десятки каналов коммуникации. Corezoid поможет управлять всеми каналами коммуникации и автоматизировать общение с помощью роботов.',
  communicationContentChanelTitle: 'Подключайте любые каналы коммуникации',
  communicationContentChanelSubTitle: 'Corezoid позволяет работать с любыми каналами коммуникации и источниками данных. Главное условие - наличие API. ',
  communicationContentRobotTitle: 'Автоматизируйте коммуникацию с помощью роботов',
  communicationContentRobotSubTitle: 'Хотите увеличить эффективность и снизить расходы на коммуникацию? Corezoid поможет создать роботов для автоматизации коммуникации. Наши лучшие клиенты автоматизировали до 80% обращений в контакт-центр.',
  communicationContentBisLogicTitle: 'Одна бизнес логика для всех каналов коммуникации',
  communicationContentBisLogicSubTitle: 'Вам больше не нужно создавать алгоритмы для каждого канала коммуникации отдельно. С помощью Corezoid вы можете создавать универсальные алгоритмы, которые работают во всех каналах коммуникации.',
  communicationContentBotTitle: 'Communications Orchestrator',
  communicationContentBotSubTitle: 'Хотите начать коммуницировать с вашими клиентами в мессенджерах? Благодаря Corezoid и набору готовых процессов “Communications Orchestrator”, вы можете подключиться ко всем популярным мессенджерам всего в несколько кликов и за считанные часы создать своих первых ботов, которые будут помогать вашим клиентам.',

  // events
  eventsHeaderTitle: 'Держи все под контролем!',
  eventsHeaderSubTitle: 'Отсутствие контроля - это одна из ключевых проблем всех компаний. Control.events поможет эффективно отслеживать и управлять событиями, которые происходят как внутри, так и снаружи компании.',
  eventsContentCheckTitle: 'Держи все под контролем',
  eventsContentCheckSubTitle: 'Подключай события из любых источников и управляйте всеми событиями компании в едином интерфейсе.',
  eventsContentDashTitle: 'Построение графа событий',
  eventsContentDashSubTitle: 'Control.events поможет визуализировать связи и настроить правила взаимодействие между разными событиями, сделав их управляемыми.',
  eventsContentWorkTitle: 'Совместная работа',
  eventsContentWorkSubTitle: 'Работайте над совместными проектами и задачами вместе с коллегами и подключайте людей вне компании и общайтесь с помощью комментариев.',
  eventsContentImportTitle: 'Импорт событий из любого источника',
  eventsContentImportSubTitle: 'Импортируйте события с помощью готовых коннекторов из всех популярных источников: Gmail, Google Drive, Calendar, JIRA, Slack, Zoom. И подключайте любые другие источники событий с помощью Corezoid.',
  eventsContentControlTitle: 'Держи все под рукой',
  eventsContentControlSubTitle: 'Control.events работает не только в браузере, но и во всех популярных мессенджерах.',

  // knowledge
  knowledgeHeaderTitle: 'Управляйте информацией и знаниями компании',
  knowledgeHeaderSubTitle: 'Информация и знания компании скрыты в данных и хранятся в десятках разрозненных ПО в разных форматах. Облачная экспертная система DeepMemo поможет объединить данные из разных источников в едином хранилище и извлечь из сырых данных информацию и знания для принятия более качественных и эффективных решений: от супер персонализации до антифрода.',
  knowledgeContentBaseTitle: 'Единая клиентская база',
  knowledgeContentBaseSubTitle: 'Собирайте информацию об использовании ресурсов компании и создавайте экспертные системы для обработки и управления данными в единой системе.',
  knowledgeContentBillingTitle: 'Биллинг',
  knowledgeContentBillingSubTitle: 'Corezoid поможет организовать управление потоком событий от ваших девайсов и настроить эскалации в режиме реального времени.',
  knowledgeContentEscalTitle: 'Автоматическая экстракция знаний и связей',
  knowledgeContentEscalSubTitle: 'Накопление сырых данных без извлечения из них пользы - бесполезное занятие. DeepMemo поможет настроить алгоритмы автоматического извлечения знаний и скрытых связей из потока данных, а полученные инсайты позволят принимать более качественные решения по управлению ваших бизнесом.',
  knowledgeContentAntifraudTitle: 'Антифрод',
  knowledgeContentAntifraudSubTitle: 'Благодаря встроенным групповым и индивидуальным лимитам, системе весов и счетчиков, DeepMemo поможет создать экспертные системы по управлению рисковыми операциями, которые принимают решения в режиме реального времени.',
  knowledgeContentSuperTitle: 'Супер персонализация',
  knowledgeContentSuperSubTitle: 'С помощью экспертных систем, DeepMemo поможет извлечь из сырых данных скрытые связи и знания о клиентах, которые позволяют создавать уникальные супер персонализированные предложения для повышения ваших продаж.',

  // people
  peopleHeaderTitle: 'Управляйте лидами, клиентами, сотрудниками, партнерами, поставщиками...',
  peopleHeaderSubTitle: 'Перенесите технологии управления людьми в Corezoid: создавайте воронки продаж, управляйте жизненным циклом клиентов, поставщиками и складскими запасами; отслеживайте выполнение и отсутствие выполнения KPI и задач сотрудников.',
  peopleContentLifeTitle: 'Автоматизируйте жизненный цикл клиента',
  peopleContentLifeSubTitle: 'Corezoid поможет декомпозировать жизнь клиента на все возможные состояния от онбординга до продажи и поддержки, а также настроить реакцию на изменение или отсутствие изменения состояния пользователя. Создавайте и запускайте процессы, которые будут автоматически вести ваших клиентов по жизненному циклу, а вам останется лишь следить за метриками и оптимизировать процессы для достижения выдающихся результатов.',
  peopleContentDeshTitle: 'Контролируйте выполнение KPI с помощью онлайн-дашбордов',
  peopleContentDeshSubTitle: 'Контролируйте ваш бизнес с помощью встроенных онлайн-дашбордов Corezoid, которые позволят следить за ключевыми метриками и принимать управленческие решения в режиме реального времени.[br][br]Создавайте новые и изменяйте существующие дашборды всего в несколько кликов без привлечения разработчиков.',
  peopleContentPulseTitle: 'Держите руку на пульсе вашего бизнеса с помощью эскалаций ',
  peopleContentPulseSubTitle: 'Сотрудник не выполнил поставленную задачу в срок? Подрядчик просрочил поставку? Партнер не обработал заявку? Corezoid поможет настроить эскалации разных уровней и получать уведомления о важных событиях в вашем бизнесе для принятия управленческих решений.',
  peopleContentControlTitle: 'Управляйте подрядчиками, поставщиками, партнерами в едином интерфейсе',
  peopleContentControlSubTitle: 'Corezoid позволяет объединить и унифицировать протоколы общения разных подрядчиков, поставщиков и партнеров для управления всеми процессами в едином интерфейсе.',

  // cloud
  cloudHeaderTitle: 'Стать облачным - легко!',
  cloudHeaderSubTitle: '«Банкинг нужен, а вот банки – нет» (с) Бил Гейтс [br][br]Признайтесь, вам нужны результаты вычислений, а не то как они происходят. Создавайте и запускайте алгоритмы любой сложности в автомасштабируемом и безопасном облаке Corezoid.',
  cloudContentHostTitle: 'Хостинг без хостинга',
  cloudContentHostSubTitle: 'Вам больше не нужно выбирать подходящий хостинг под каждое приложение или язык программирования. Достаточно просто зарегистрироваться в Corezoid, после чего мы автоматически развернем окружение под вас, где вы сможете создавать и хостить алгоритмы любой сложности и на любом языке программирования всего в несколько кликов.',
  cloudContentDeployTitle: 'Deploy в 1 клик',
  cloudContentDeploySubTitle: 'С Corezoid вам больше не нужно заморачиваться с инструментами continuous integration (CI). Деплойте и применяйте изменения в процессах в режиме реального времени всего в 1 клик.',
  cloudContentVersTitle: 'Версионированние',
  cloudContentVersSubTitle: 'Создавайте новые, дорабатывайте существующие или откатывайтесь к предыдущей версии процессов - все доступные в GIT функции версионирования доступны в Corezoid.',
  cloudContentScaleTitle: 'Автоматическое масштабирование',
  cloudContentScaleSubTitle: 'Концентрируйтесь на бизнесе, а не проблемах масштабирования. Corezoid нативно обеспечивает автоматическое горизонтальное масштабирование, что позволит вам не содержать штат devops и разработчиков для решения подобных проблем.',
  cloudContentPerformanceTitle: 'Производительность',
  cloudContentPerformanceSubTitle: 'Corezoid позволяет создавать сервисы, которые без проблем выдерживают нагрузку в сотни тысяч запросов в секунду. В работе с ведущими мировыми компаниями мы зарекомендовали себя как надежное решения для высоконагруженных систем.',
  cloudContentSecurityTitle: 'Безопасность',
  cloudContentSecuritySubTitle: 'Corezoid был рожден в банковской отрасли, где вопрос безопасности - критически важен. Corezoid успешно прошел ряд аудитов от таких компаний как WU, METRO, AWS и соответствует требованиям PCI DSS и ISO27001. Также Corezoid является рекомендованным решением для финансовой отрасли в рамках программы AWS Financial Services Competency.',

  // resource
  resourceHeaderTitle: 'Ресурсы',
  resourceHeaderButtonBlogTitle: 'Новости',
  resourceHeaderButtonCaseTitle: 'Кейсы',
  resourceBlogTitle: 'Новости',
  resourceBlogButtonText: 'Перейти в новости',
  resourceBlogMore: 'Читать полностью',
  resourceCaseTitle: 'Кейсы',
  resourceCaseSubTitle: 'Узнайте как разные компании используют Corezoid Actor Engine для управления бизнесом, сокращения расходов, увеличения скорости time-to-market и добиваются выдающихся результатов.',
  resourceCaseButtonText: 'Загрузить еще',
  resourceCaseItem1Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem1More: 'Читать полностью',
  resourceCaseItem2Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem2More: 'Читать полностью',
  resourceCaseItem3Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem3More: 'Читать полностью',
  resourceCaseShowMore: 'Показать еще',

  // media
  mediaTitle: 'Медиа',
  mediaSubTitle: 'Новости, статьи и другие материалы, связанные c Corezoid, Inc.',
  mediaShowMore: 'Загрузить еще',

  // slider
  sliderTitle: 'Как Corezoid помогает клиентам',
  sliderResult: 'Результат',
  sliderTCB: 'TBC запустил на базе Corezoid новый "челленджер банк" под названием "Space bank". Это банк без отделений, где в основании клиентского обслуживания лежит мобильное приложение. Space Bank запущен на базе Corezoid в рекордные сроки: за 8 месяцев, силами команды из 20 человек.',
  sliderAvon: 'В Avon перешли от цикла разработки по принципу “Waterfall” к сбору процессов на Corezoid. Это позволило Avon сфокусировать энергию сотрудников на быстром построении бизнес-процессов и постоянном улучшении на основании сбора обратной связи от пользователей в режиме реального времени.[br][br]Такой подход позволил Avon заложить основу “цифрового ядра” компании. Теперь бизнес-аналитики Avon могут самостоятельно собирать и поддерживать свои бизнес-процессы без необходимости стоять в очереди на разработку в департаменте ИТ.',
  sliderMetro: 'На базе Corezoid реализовано мобильное приложение МЕТРО, а также бот в Viber мессенджере. Бот обеспечивает выдачу членских карт МЕТРО в 1 клик, вместо заполнения анкет и пластиковых карт. Также, благодаря Corezoid, METRO может отправлять пользователям персонализированные триггерные уведомления.',
  sliderMetroName: 'Марина Волошина',
  sliderMetroStatus: 'Руководитель CRM в МЕТРО',
  sliderPumb: 'Corezoid стал огромным катализатором и ускорителем цифровой трансформации в ПУМБе. Corezoid позволил сильно расширить узкое горлышко ИТ-ресурсов. Когда есть “цифровое ядро” в виде процессной системы, которой управляет сам бизнес, изменения в организации происходят на порядки быстрее. Бизнес начинает самостоятельно собирать процессы, мыслить категориями MVP, строить и улучшать прототипы.',
  sliderPumbName: 'Андрей Бегунов',
  sliderPumbStatus: 'CTO в ПУМБ',
  sliderSpaceBank: 'Мы изучили технологию Corezoid, которая является сейчас нашей основной системой, где мы ведем почти весь процесс разработки. Могу сказать, что все наши основные продукты, процессы, и серверная часть мобильного приложения – построены на платформе Corezoid. Мы оставили старый legacy core и построили все важные процессы, как нам хотелось, как мы видели, не подключая при этом вендора.',
  sliderSpaceBankName: 'Лаша Гургенидзе',
  sliderSpaceBankStatus: 'Руководитель проекта Space Neobank',
  sliderMambu: 'Что привлекло мое внимание в Corezoid – это фундаментальный подход к самой технологии. Corezoid – это не еще один BPM. Сorezoid базируется на фундаментальных принципах: математическая модель платформы основана на теории конечных автоматов. Corezoid воплощает в себе концепцию “машины состояний”.',
  sliderMambu2: 'Уникальное предложение Corezoid заключается в том, что люди могут запросто работать с API. Им больше не нужно писать код, не нужно его как-то по-особенному разворачивать, тестировать, архитектуру создавать. Это все уже есть. Единственное, что нужно – это понимание, как должен выглядеть процесс, и, собственно, настройка самого процесса.',
  sliderMambuName: 'Бен Голдин',
  sliderMambuStatus: 'CTO в MAMBU',
  sliderTas: 'С точки зрения технологий, признаюсь, пробовали изучать альтернативы Corezoid. И поняли, что на данный момент без альтернатив. На Corezoid мы делаем процессы, где чувствуем, что нужна динамика, где есть желание чуть ли не каждый день что-то менять, экспериментировать на лету, запускать несколько разных вариантов бизнес-процесса с конечной оценкой эффективности.',
  sliderTasName: 'Александр Николенко',
  sliderTasStatus: 'Руководитель проекта “ТАС24 Бизнес”',
  sliderLanit: 'Скорость вывода нового продукта на рынок, универсальность инструментов для решения различных задач, гибкость настройки процессов — множество факторов влияет на успех. Появление таких высокотехнологичных продуктов, как Corezoid, является катализатором для дальнейшего роста трансформационных процессов в компаниях. Когда есть цифровое ядро в виде процессной системы, которой управляет сам бизнес, изменения в организации происходят намного быстрее.',
  sliderLanitName: 'Игорь Потоцкий',
  sliderLanitStatus: 'Генеральный директор «ЛАНИТ — Би Пи Эм»',
  sliderButton: 'Прочитать кейс полностью',
  sliderGlovo: 'Я знал про Corezoid. И знал, что есть компания Middleware, которая подходит к делу по уму и которая предлагает масштабируемые решения. Сейчас Middleware позволяет Glovo намного быстрее интегрировать новых партнеров.',
  sliderGlovoName: 'Дмитрий Расновский',
  sliderGlovoStatus: 'GM Glovo Ukraine',
  sliderBlueFinance: 'Мы считаем, что Corezoid изменяет правила игры. Благодаря Corezoid мы сместили акцент с пожаротушения на автоматизацию и совершенствование наших процессов. Теперь мы еженедельно выпускаем новые релизы, которые ранее требовали месяцы разработки. Сейчас мы работаем над новым полностью автоматизированным процессом обработки кредитной заявки, который еще несколько месяцев назад казался нам вовсе невозможным.',
  sliderBlueFinanceName: 'Хулио Гомез',
  sliderBlueFinanceStatus: 'СEO Blue Finance Ibérica',

  // pricing
  pricingCorezoidCloud: 'Corezoid Cloud',
  pricingSupport: 'Поддержка',

  pricingCloudTitle: 'Тарифы',
  pricingCloudSubTitle: 'Оплачивайте столько, сколько используете',
  pricingCloudState: 'Тактов/месяц',
  pricingCloudRequests: 'Запросов/секунду',
  pricingCloudPriceMonth: '/месяц',
  pricingCloudButtonText: 'ДАЛЕЕ',
  pricingCloudBilled: ' ',
  pricingCloudMonthly: 'Ежемесячно',
  pricingCloudYearly: 'ежегодно',
  pricingCloudPromoTrialTitle: '30-дневный пробный период',
  pricingCloudPromoTrialText: 'Получите 30 дней или 1 миллион тактов бесплатно для изучения Corezoid.',
  pricingCloudPromoDeployTitle: 'Private Cloud / On-premises',
  pricingCloudPromoDeployText: 'Хотите запустить Corezoid в вашем окружении? Свяжитесь с нами!',
  pricingPayType: 'Выберите метод оплаты',
  pricingPayCard: 'Платежная карта',
  pricingPayInvoice: 'Счет',
  pricingPaySummary: 'Тариф',
  pricingPaySummaryProduct: 'Продукт',
  pricingPaySummaryHosting: 'Хостинг',
  pricingPaySummaryPayment: 'Метод оплаты',
  pricingPaySummaryLicense: 'Лицензия',
  pricingPaySummaryRPS: 'RPS',
  pricingPaySummaryState: 'Состояние изменений / месяц',
  pricingPaySummaryTotal: 'Всего',
  pricingPaySummaryBuy: 'Купить Corezoid',
  pricingPayBillingInfo: 'Информация по счету',

  // caab
  caabHeaderTitle: 'Corezoid as a Back-end (CaaB)',
  caabHeaderSubTitle: 'Бессерверное управление вашим сайтом или мобильным приложением ',
  caabButton: 'ПОПРОБОВАТЬ CaaB',
  caabCloudTitle: 'Бессерверная архитектура',
  caabCloudText: 'Создавайте и управляйте бэк-ендом веб и мобильных приложений без серверов, разработчиков и DevOps.',
  caabApiTitle: 'Процессы как API',
  caabApiText: 'Corezoid позволяет создавать процессы, которые являются API для ваших приложений.',
  caabEventsTitle: 'Обработка событий',
  caabEventsText: 'Настройте обработку всех событий ваших веб и мобильных приложений в режиме реального времени.',
  caabDashbordsTitle: 'Онлайн дашборды',
  caabDashbordsText: 'Встроенные дашборды позволяют держать руку на пульсе вашего бизнеса в режиме реального времени.',
  caabEscalateTitle: 'Онлайн эскалации',
  caabEscalateText: 'Настраивайте уведомления при наступлении целевых событий или сбоев в режиме реального времени. ',
  caabDeployTitle: 'Деплой в 1 клик',
  caabDeployText: 'Все изменения процессов ваших приложений моментально деплоятся в 1 клик.',

  // aboutUs
  aboutUsTitle: 'О Corezoid Inc',
  aboutUsSubTitle: '[b]Наша миссия - сделать каждую компанию в мире технологическим магнатом и научить взаимодействовать с другими компаниями.[/b][br][br]Corezoid Inc - технологическая компания, которая создает операционную систему и прикладные программы для цифровизации бизнеса. Компании всех масштабов - от стартапов до мировых лидеров из Fortune 500 - пользуются нашими продуктами для управления своим бизнесом.',
  aboutUsFormTitle: 'Свяжитесь с нами',
  aboutUsCompanyTitle: 'Лучшие компании мира доверяют нам',
  aboutUsCompanySubTitle: 'Клиенты из 120 стран мира используют Corezoid для развития своего бизнеса.',
  aboutUsEmployeesTitle: 'Команда',
  aboutUsEmployeesSubTitle: 'Головной офис Corezoid находится в Redwood (US), офис разработки - в Украине.',
  aboutUsEmployeesInfoYearTitle: '2014',
  aboutUsEmployeesInfoYearText: 'год основания',
  aboutUsEmployeesInfoEmployeesTitle: '80+',
  aboutUsEmployeesInfoEmployeesText: 'сотрудников',
  aboutUsTechnologyTitle: 'Технологии',
  aboutUsTechnologySubTitle: 'Стек технологических продуктов Corezoid Inc. для управления вашим бизнесом',

  // innovation
  historyInnovationTitle: 'История инноваций',
  historyInnovationSubTitle: 'Инновации, разработанные Александром Витязем',

  // partnership
  partnershipTitle: 'Партнерство',
  partnershipSubTitle: 'Используйте Corezoid для создания инновационных цифровых продуктов для ваших клиентов.',
  partnershipPlusesTitle: 'Почему Corezoid?',
  partnershipPlusesEcoTitle: 'Единая экосистема для управления всеми процессами компании',
  partnershipPlusesEcoSubTitle: 'Приходится искать нишевые продукты и нанимать “узких” специалистов для решения клиентских задач? Corezoid - это универсальный инструмент, который позволяет решать практически любой класс задач руками одних и тех же специалистов.',
  partnershipPlusesProtoTitle: 'Быстрое прототипирование и внесение изменений',
  partnershipPlusesProtoSubTitle: 'Клиент хочет видеть прототип решения как можно раньше? Без проблем. Corezoid позволяет создавать прототипы продуктов без привлечения разработчиков за считанные часы и так же быстро вносить изменения в соответствии с изменениями требований клиента.',
  partnershipPlusesTimeTitle: 'Сокращение time-to-market до 0',
  partnershipPlusesTimeSubTitle: 'Вы сможете развиваться вместе с рынком или даже быстрее. Забудьте об очередях на разработку и зависимости от ИТ-департаментов. Благодаря низкому порогу входа, любой менеджер может изучить Corezoid и создавать готовые бизнес-продукты за дни и недели, а не месяцы и годы.',
  partnershipPlusesHostTitle: 'Хостинг без хостинга',
  partnershipPlusesHostSubTitle: 'Создавайте продукты в облаке Corezoid, которое поддерживает автоматическое горизонтальное масштабирование и вам больше не понадобиться содержать дорогостоящих devops под каждую облачную платформу.',
  partnershipPartnersTitle: 'Список сертифицированных партнеров',
  partnershipPartnersUkraine: 'Страна: Украина',
  partnershipPartnersEurope: 'Регион: Европа',
  partnershipPartnersWorldwide: 'Регион: Весь мир',
  partnershipPartnersRussia: 'Страна: Россия',
  partnershipPartnersEuropeCIS: 'Регион: Европа и СНГ',
  partnershipPartnersCompetenciesTitle: 'Компетенции:',
  partnershipPartnersButton: 'Перейти на сайт партнера',
  partnershipCertificationTitle: 'Хотите стать сертифицированным партнером Corezoid?',
  partnershipCertificationSubTitle: 'Сертифицированные партнеры Corezoid получают бонусы за привлечение клиентов, а также имеют доступ к бесплатным мастер-классам для повышения квалификации сотрудников, потоку лидов с сайта Corezoid и списку сертифицированных специалистов, которые ищут работу.[br][br][b]Станьте партнером Corezoid прямо сейчас![/b]',
  partnershipCertificationButton: 'Подать заявку на партнерство',
  partnershipVacancyTitle: 'Наймите профессионального разработчика Corezoid',
  partnershipVacancySubTitle: 'Сертифицированные специалисты Corezoid смогут ознакомиться с условиями вакансии и предложить свою кандидатуру.[br][ol][li]Вы публикуете вакансию[br][/li][li]Мы рассылаем вакансию всем сертифицированным специалистам Corezoid[br][/li][li]Мы передаем вам резюме заинтересованных специалистов[br][/li][li]Вы продолжаете коммуникацию с заинтересованными специалистами для дальнейшего найма[/li][/ol]',
  partnershipVacancyButton: 'Опубликовать вакансию',

  // jobs
  jobsTitle: 'Вакансии',
  jobsSubTitle: 'Мы всегда ищем лучшие таланты в нашу команду. Если вы хотите стать частью компании, которая изменяет мир, найдите подходящую вам вакансию и подайте заявку.',
  jobsMwTitle: 'Почему Corezoid Inc?',
  jobsMwAmbitiousTitle: 'Одна из самых амбициозных и инновационных технологических компаний мира',
  jobsMwAmbitiousSubTitle: 'Мы работаем с разными клиентами со всего мира - от стартапов до крупнейших международных компаний. Экосистема наших технологий помогает компаниям управлять бизнесом, создавать инновации, сокращать издержки и запускать продукты в сотни раз быстрее.',
  jobsMwInterestingTitle: 'Сложно, но интересно.',
  jobsMwInterestingSubTitle: 'Мы привыкли работать на стыке исследовательской деятельности, обучения новым технологиям и выполнения высоких бизнесовых KPI. Работа в нашей команде под силу не каждому, но с нами вы будете развиваться экспоненциально.',
  jobsListTitle: 'Список вакансий Corezoid Inc.',
  jobsListSubTitle: 'Мы всегда ищем лучшие таланты в нашу команду. Если вы хотите стать частью компании, которая изменяет мир, найдите подходящую вам вакансию и подайте заявку.',
  jobsListLastItem: 'Не нашли вакансию?[br]Мы ищем проактивных людей![br][br]Отправить резюме',
  jobsListButton: 'Присоединиться',
  jobsListButtonInfo: 'Детали',
  jobsPartnerListTitle: 'Вакансии для сертифицированных специалистов Corezoid от клиентов и партнеров',
  jobsPartnerListSubTitle: 'Вы сертифицированный специалист Corezoid или хотите стать одним из них? С навыками работы в Corezoid вы всегда будете востребованы на рынке труда.',
  jobsPartnerListButton: 'Описание вакансии',
  jobsCoursesTitle: 'Хотите стать сертифицированным специалистом Corezoid?',
  jobsCoursesSubTitle: 'Пройдите обучение и подтвердите свои навыки работы с Corezoid с помощью сертификации.',
  jobsCoursesButton1: 'Изучить Corezoid',
  jobsCoursesButton2: 'Подробнее о сертификации',

  // learn
  learnTitle: 'Изучение Corezoid',
  learnSubTitle: 'Хотите начать изучение Corezoid, но не знаете с чего начать? Мы предлагаем разные форматы обучения для разных типов клиентов и уровней специалистов: от бесплатных учебных материалов до индивидуального корпоративного обучения. Выберите подходящий вам формат и график обучения и двигайтесь к успеху вместе с Corezoid!',
  learnOrderWorkshopButton: 'Купить воркшоп',
  learnContentDescription: 'Медиа-материалы в разделах ниже помогут вам быстро начать пользоваться Corezoid независимо от вашего уровня подготовки, а также решать конкретные задачи, пользуясь готовыми примерами.',
  learnVideoTitle: 'Быстрый старт Corezoid.com',
  learnVideoPart1Title: 'Обзор рабочей среды, узел Condition',
  learnVideoPart2Title: 'Узлы API Call и Set Parameter',
  learnVideoPart3Title: 'Узлы Call process и Reply to Process',
  learnVideoPart4Title: 'Узлы Copy Task, Modify Task, Waiting for callback',
  learnVideoPart5Title: 'Обзор State Diagram',
  learnVideoPart1List1: 'В первом видео мы рассматриваем рабочее пространство для управления вашими процессами, а также показываем, как просто и быстро создать свой первый процесс.',
  learnVideoPart1List2: 'Каждый процесс Corezoid состоит из узлов разных типов.',
  learnVideoPart1List3: 'У каждого типа узла - своя логика обработки данных.',
  learnVideoPart1List4: 'В этом видео вы узнаете, как работать с узлом Condition, который позволяет:',
  learnVideoPart1List4Item1: 'задавать условия перемещения и обработки заявки в процессе.',
  learnVideoPart2List1: 'В этом видео мы рассматриваем работу с узлом Set Parameter, который позволяет:',
  learnVideoPart2List1Item1: 'добавлять в заявку новые параметры и устанавливать их значения;',
  learnVideoPart2List1Item2: 'изменять значения существующих параметров в заявке.',
  learnVideoPart2List2: 'Также рассматривается работа с узлом API Call, который позволяет:',
  learnVideoPart2List2Item1: 'вызывать внешние API.',
  learnVideoPart3List1: 'В данном видео вы можете узнать о работе с узлами Call Process, который позволяет:',
  learnVideoPart3List1Item1: 'вызывать другой процесс Corezoid из вашего процесса',
  learnVideoPart3List2: 'и Reply to Process, который позволяет:',
  learnVideoPart3List2Item1: 'отсылать ответ в другой процесс.',
  learnVideoPart4List1: 'В данном видео рассмотрен узел Copy Task, который позволяет:',
  learnVideoPart4List1Item1: 'скопировать всю/часть заявки в другой доступный процесс.',
  learnVideoPart4List2: 'и Modify Task, позволяющий:',
  learnVideoPart4List2Item1: 'изменять заявку в другом доступном процессе.',
  learnVideoPart4List3: 'Также в этом видео демонстрируется работа с узлом Waiting for Сallback, который позволяет:',
  learnVideoPart4List3Item1: 'удерживать задачу в узле до получения ответа от внешней системы.',
  learnVideoPart5List1: 'В видео показана работа со State Diagram, которая оптимизирована для хранения заявок. B данном примере она используется для управления состояниями клиентов.',
  learnVideoPart5List1Item1: ' ',
  learnVideoPart5List2: 'Также продемонстрировано использование узлов Copy Task в State Diagram.',
  learnContentStartTitle: 'Быстрый старт',
  learnContentStartSubTitle: 'Пошаговое руководство для начала работы с Corezoid',
  learnContentStartText: 'Только начали работать с Corezoid? Мы сделали пошаговое руководство, которое позволит вам быстро создать свой первый процесс и покажет, что изучить Corezoid - действительно просто.',
  learnContentStartButton: 'Начать изучение',
  learnContentStartButtonLink: 'https://doc.corezoid.com/v2/docs/quick-start',
  learnContentTutorialTitle: 'Туториалы',
  learnContentTutorialSubTitle: 'Бесплатные учебные онлайн-материалы и проекты',
  learnContentTutorialText: 'Учитесь и повышайте свои навыки с помощью бесплатных материалов и проектов как для начинающих, так и для опытных пользователей Corezoid.',
  learnContentTutorialButton: 'Начать изучение',
  learnContentTutorialButtonLink: 'https://doc.corezoid.com/v2/docs/tutorials',
  learnContentDocTitle: 'Документация',
  learnContentDocSubTitle: 'Справочная по всем основным сущностям, функциям и API',
  learnContentDocText: 'Главное руководство пользователя, которое содержит описание всего, что необходимо для начальной и продвинутой работы с Corezoid.',
  learnContentDocButton: 'Изучить документацию',
  learnContentDocButtonLink: 'https://doc.corezoid.com/docs',
  learnContentWorkShopTitle: 'Воркшопы',
  learnContentWorkShopSubTitle: 'Самый быстрый способ погружения в Corezoid - индивидуальное обучение',
  learnContentWorkShopText: 'Мы создаем индивидуальную программу обучения под ваши потребности, и вы на выходе получаете не только обученную технологии Corezoid команду, но и прототип реального бизнес-процесса.',
  learnContentWorkShopButton: 'Подробнее о воркшопах',
  learnContentCertificationTitle: 'Сертификация',
  learnContentCertificationSubTitle: 'Подтвердите свои навыки владения Corezoid и начните новый этап вашей карьеры',
  learnContentCertificationText: 'Спрос на сертифицированных специалистов Corezoid растет с каждым днем. Подтвердите свою квалификацию и мы поможем вам трудоустроиться у наших партнеров и клиентов.',
  learnContentCertificationButton: 'Пройти сертификацию',
  learnContentCommunityTitle: 'Сообщество',
  learnContentCommunitySubTitle: 'Присоединяйтесь к сообществу специалистов Corezoid',
  learnContentCommunityText: 'Мы создали Corezoid Community - место, где Вы сможете обмениваться опытом, задавать вопросы, находить ответы и обсуждать идеи.',
  learnContentCCommunityButton: 'Присоедениться',
  learnInformation: '96% корпоративных клиентов начинают изучение Corezoid с индивидуального воркшопа.',

  // tutorials
  tutorialsTitle: 'Туториалы',
  tutorialsSubTitle: 'Бесплатные учебные статьи и десятки учебных проектов с пошаговым руководством помогут вам качественно и быстро разобраться с Corezoid для создания своих инновационных проектов и решения бизнес-задач.',
  tutorialsButton: 'Заказать воркшоп',
  tutorialsContentTitle: 'Учебные проекты',
  tutorialsContentOAuthText: 'Вы научитесь использовать Google API с помощью Google OAuth 2.0',
  tutorialsContentGmailText: 'Вы научитесь использовать Gmail API для получения непрочитанных писем и другой информации из почты.',
  tutorialsContentSheetsText: 'Вы научитесь добавлять записи в Google Sheets используя API Google AppScript через Corezoid.',
  tutorialsContentFirebaseText: 'Этот туториал научит Вас работать с облачной базой данных Firebase с помощью API.',

  tutorialsContentMessagebirdText: 'Вы научитесь настраивать процесс вызова API MessageBird для отправки SMS и проверки статуса доставки отправленного SMS.',
  tutorialsContentButtonMessagebirdLink: 'https://doc.corezoid.com/v2/docs/messagebird',
  tutorialsContentEsputnikText: 'Вы научитесь отправлять email через API eSputnik и проверять статус доставки email.',
  tutorialsContentButtonEsputnikLink: 'https://doc.corezoid.com/v2/docs/esputnik',
  tutorialsContentQueueTitle: 'Очередь заявок',
  tutorialsContentQueueText: 'Вы научитесь работать с очередями заявок в процессах Corezoid.',
  tutorialsContentButtonQueueLink: 'https://doc.corezoid.com/v2/docs/creating-and-managing-a-request-queue',
  tutorialsContentButton: 'Начать изучение',
  tutorialsContentButtonOAuthLink: 'https://doc.corezoid.com/v2/docs/google-oauth',
  tutorialsContentButtonGoogleSheetsLink: 'https://doc.corezoid.com/v2/docs/google-appscripts',
  tutorialsContentButtonGmailLink: 'https://doc.corezoid.com/v2/docs/google-gmail',
  tutorialsContentButtonFirebaseLink: 'https://doc.corezoid.com/v2/docs/firebase',
  tutorialsContentButtonBotPlatformLink: 'https://doc.corezoid.com/v2/docs/bot-platform-21',
  tutorialsContentBotText: 'Создайте своего первого бота и подключите его ко всем популярным мессенджерам',
  tutorialsLevel: 'Уровень',
  tutorialsNewbie: 'Новичок',
  tutorialsAdvanced: 'Продвинутый',
  tutorials1hour: '1 час',
  tutorials3hours: '3 часа',
  tutorials5hours: '5 часов',
  tutorialsDuration: 'Длительность',

  // workshops
  workshopsTitle: 'Воркшоп',
  workshopsSubTitle: 'Самый быстрый способ изучения Corezoid. Мы создаем индивидуальную программу обучения под ваши потребности, и вы на выходе получаете не только обученную технологии Corezoid команду, но и прототип реального бизнес-процесса.',
  workshopsButton1: 'Купить воркшоп',
  workshopsButton2: 'Связаться с нами',
  workshopsInfoDay: '3',
  workshopsInfoDayText: 'дня интенсивого обучения Corezoid',
  workshopsInfoBusiness: '100%',
  workshopsInfoBusinessText: 'погружение в бизнес-кейс клиента',
  workshopsInfoProto: '1',
  workshopsInfoProtoText: 'прототип рабочего бизнес-процесса',
  workshopsInfoParticipants: '1-6',
  workshopsInfoParticipantsText: 'обучающихся со стороны клиента',
  workshopsProgramText: 'Программа обучения',
  workshopsProgramListNode: 'Изучение всех узлов и паттернов программирования в Corezoid',
  workshopsProgramListService: 'Изучение инструментов и интеграций с сервисами, которые необходимы для реализации вашего бизнес-кейса.',
  workshopsProgramListCases: 'Создание вашего бизнес-кейса',
  workshopsFormatTitle: 'Формат обучения',
  workshopsFormatOnlineTitle: 'Онлайн воркшоп',
  workshopsFormatOnlineText: 'Мы создаем групповой звонок в Zoom / Skype или другом удобном для вас канале видеосвязи и проводим 3-дневное удаленное обучение ваших специалистов',
  workshopsFormatOnlinePrice: 'Стоимость воркшопа: 1,500$',
  workshopsFormatOnlineButton: 'Купить воркшоп',
  workshopsFormatOfflineTitle: 'Оффлайн воркшоп',
  workshopsFormatOfflineText: '[b]Доступны 2 варианта оффлайн-воркшопа:[/b][br][ol][li]Вы приезжаете к нам в офис для полного погружения в Corezoid и отключения от рабочей суеты. [br][/li][li]Мы отправляем к вам наших специалистов для обучения.*[/li][/ol]',
  workshopsFormatOfflinePrice: 'Стоимость воркшопа: 1,500$',
  workshopsFormatOfflineComment: '* плюс командировочные расходы',
  workshopsFormatOfflineButton: 'Купить воркшоп',
  workshopsLicenseTitle: 'Станьте официальным учебным центром или инструктором Corezoid',
  workshopsLicenseCenterTitle: 'Центр обучения Corezoid',
  workshopsLicenseCenterText: 'В качестве официального партнера по обучению вы получаете лицензию на проведения учебных семинаров, сертификаций и курсов Corezoid.',
  workshopsLicenseCenterButton: 'Подать заявку',
  workshopsLicenseInstructorTitle: 'Инструктор Corezoid',
  workshopsLicenseInstructorText: 'Пройдите сертификацию и присоединитесь к нашему сообществу сертифицированных инструкторов и начните обучать людей и компании Corezoid.',
  workshopsLicenseInstructorButton: 'Подать заявку',

  // certification
  certificationTitle: 'Сертификация',
  certificationSubTitle: 'Спрос на сертифицированных специалистов Corezoid растет с каждым днем. Подтвердите свою квалификацию и вам откроется доступ к сообществу сертифицированных специалистов и партнеров Corezoid, которое поможет вам найти новые интересные проекты и даже работу.',
  certificationCertTitle: 'Сертификация Corezoid',
  certificationCertSubTitle: 'Наличие сертификата Corezoid демонстрирует работодателю что у вас есть необходимые навыки и знания для решения поставленных задач.',
  certificationCertJuniorTitle: 'Junior',
  certificationCertJuniorSubTitle: 'Сдайте онлайн-тестирование на 170+ баллов и получите сертификат Junior Corezoid Developer.',
  certificationCertJuniorButton: 'Подтвердить статус Junior',
  certificationCertJuniorButtonLink: '/certification/junior/',
  certificationCertMiddleTitle: 'Middle',
  certificationCertMiddleSubTitle: 'Для получения уровня Middle вам необходимо глубокое изучение документации Corezoid, умение работать не только с внешними API, но и внутренним API Corezoid; понимание основных паттернов разработки на Corezoid и наличие минимум 10 реализованных проектов.',
  certificationCertMiddleButton: 'Подтвердить статус Middle',
  certificationCertSeniorTitle: 'Senior',
  certificationCertSeniorSubTitle: 'Уровень Senior доступен для специалистов, которые работают с Corezoid от 1 года и успешно создали 25 и более проектов разного уровня сложности. Специалистов с уровнем Senior должен понимать как работать со всеми типами данных в Corezoid, в том числе XML, SOAP; иметь опыт автоматической генерации процессов с помощью API Corezoid; уметь жонглировать лучшие практиками работы с Corezoid для оптимального решения бизнес-задач.',
  certificationCertSeniorButton: 'Подтвердить статус Senior',
  certificationCorpTitle: 'Корпоративная сертификация',
  certificationCorpSubTitle: 'Мы создаем групповой звонок в Zoom / Skype или другом удобном для вас канале видеосвязи и проводим 3-дневный удаленное обучение ваших специалистов ',
  certificationCorpButtons: 'Заказать корпоративную сертификацию',
  certificationPartnerTitle: 'Сертификация партнеров Corezoid',
  certificationPartnerSubTitle: 'Сертифицированный партнер Corezoid помимо статуса, получает доступ к закрытому сообществу партнеров для обмена опытом и клиентами, эксклюзивным вебинарам для повышения квалификации сотрудников, потоку лидов с сайта Corezoid.com и списку сертифицированных специалистов, которые ищут работу.',
  certificationPartnerButtons: 'Пройти сертификацию для партнеров',

  // support
  supportTitle: 'Поддержка',
  supportSubTitle: 'Выберите ваш тарифный план',
  supportSalesTest1: 'Стоимость приведена для одной компании, без учета НДС, налога на репатриацию и командировочных расходов.',
  supportSalesTest2: 'Обязательные пакеты поддержки для типов хостинга “Private Cloud” и “On-premises” начинаются от “Business”.',
  supportSalesQuestion: 'Хотите купить Corezoid или есть вопросы по поддержке?',
  supportSalesButtonText: 'Свяжитесь с нами!',

  supportListRowTitle: '',
  supportListRowService: 'Клиентская поддержка и сообщество',
  supportListRowHealth: 'Состояние системы и уведомления',
  supportListRowTechSupport: 'Техническая поддержка',
  supportListRowTechChannels: 'Каналы поддержки',
  supportListRowTechSeverity: 'Уровень проблемы и время ответа',
  supportListRowArchSupport: 'Архитектурная поддержка',
  supportListRowEnvSupport: 'Поддержка окружения',
  supportListRowGuidance: 'Проактивные подсказки',
  supportListRowLauSupport: 'Помощь при старте',
  supportListRowTrainings: 'Обучение',
  supportListRowPrice: 'Стоимость/месяц',

  supportListBaseTitle: 'BASIC',
  supportListBaseService: 'Доступ 24/7 к клиентским сервисам, документации, white papers, и поддержке',
  supportListBaseHealth: 'Доступ к странице Health Check Monitoring',
  supportListBaseTechSupport: '9/5',
  supportListBaseTechChannels: 'Email',
  supportListBaseTechSeverity: '> 1 день',
  supportListBaseArchSupport: 'Не предусмотрено',
  supportListBaseEnvSupport: 'Не предусмотрено',
  supportListBaseGuidance: 'Не предусмотрено',
  supportListBaseLauSupport: 'Не предусмотрено',
  supportListBaseTrainings: 'Не предусмотрено',
  supportListBasePrice: 'Бесплатно',

  supportListBusinessTitle: 'BUSINESS',
  supportListBusinessService: 'Доступ 24/7 к клиентским сервисам, документации, white papers, и поддержке',
  supportListBusinessHealth: 'Доступ к странице Health Check Monitoring',
  supportListBusinessTechSupport: '9/5',
  supportListBusinessTechChannels: 'Email / чат',
  supportListBusinessTechSeverity: 'Общие рекомендации: до 24 часов,[br][br]Системный сбой: до 12 часов,[br][br]Сбой в продуктивной среде: до 6 часов,[br][br]Остановка продуктивной среды: до 3 часов',
  supportListBusinessArchSupport: 'Рекомендации на основании анализа ваших кейсов',
  supportListBusinessEnvSupport: 'Обзор, рекомендации и отчеты',
  supportListBusinessGuidance: 'Не предусмотрено',
  supportListBusinessLauSupport: 'Руководство по настройке системы',
  supportListBusinessTrainings: 'Не предусмотрено',
  supportListBusinessPrice: '$2,000',

  supportListInterpriseTitle: 'ENTERPRISE',
  supportListInterpriseService: 'Доступ 24/7 к клиентским сервисам, документации, white papers, и поддержке',
  supportListInterpriseHealth: 'Доступ к странице Health Check Monitoring',
  supportListInterpriseTechSupport: '24/7',
  supportListInterpriseTechChannels: 'Email / чат / телефон',
  supportListInterpriseTechSeverity: 'Общие рекомендации: до 24 часов, [br][br]Системный сбой: до 12 часов, [br][br]Сбой в продуктивной среде: до 3 часов, [br][br]Остановка продуктивной среды: до 1 часов, [br][br]Критическая ошибка, которая останавливает бизнес: до 15 минут.',
  supportListInterpriseArchSupport: 'Персональные консультации на основании анализа ваших продуктов, приложений и архитектуры',
  supportListInterpriseEnvSupport: 'Обзор, рекомендации и отчеты',
  supportListInterpriseGuidance: 'Выделенный технический менеджер',
  supportListInterpriseLauSupport: 'Руководство по настройке системы и лучшим практикам',
  supportListInterpriseTrainings: '1 курс в месяц',
  supportListInterprisePrice: 'TBD',

  // questions
  questionsTitle: 'ЧАВО',
  questionsSubTitle: 'Есть вопросы? Мы с радостью поможем!',
  questionsCalcTitle: 'Что такое такты и как они рассчитываются?',
  questionsCalcText: '[b]Такт - это переход заявки из одного узла в другой.[/b][br]На анимации вы можете увидеть как заявка потребляет 3 такта пройдя между 4 узлами. [br]Общее кол-во тактов можно рассчитать по следующей формуле: [b]SC = (N - 1) * T[/b], где ',
  questionsCalcTextUl1: '[b]SC[/b] - кол-во тактов',
  questionsCalcTextUl2: '[b]N[/b] - кол-во узлов в процессе',
  questionsCalcTextUl3: '[b]T[/b] - кол-во поступивших заявок в процесс',
  questionsRequestTitle: 'Что такое запросы в секунду и как они рассчитываются?',
  questionsRequestText: 'Запросы в секунду - это количество заявок, которое может поступить в процесс в течение 1 секунды. Например, тарифный пакет M имеет ограничение в 10 запросов в секунду - это означает, что если в процесс поступит 11 заявок за 1 секунду, то последняя заявка не будет доставлено в процесс и вам придет сообщение о превышении лимита запросов в секунду.',
  questionsAfterTitle: 'Что произойдет если я использую все такты, предусмотренные в моем тарифном пакете?',
  questionsAfterText: 'После потребления всех тактов, предусмотренных вашим тарифным пакетом, мы ограничим скорость до 2 запросов в секунду до проведения оплаты. Если общее кол-во потребленных тактов будет больше 120% от кол-во тактов в вашем тарифном пакете, мы заблокируем ваш аккаунт до проведения оплаты.',
  questionsSupportTitle: 'Включена ли поддержка в указанные тарифные планы?',
  questionsSupportText: 'Да, все клиенты получают базовый бесплатный пакет поддержки. Другие планы поддержки вы можете приобрести отдельно. [iurl=/ru/pricing/support/]Подробнее о поддержке.[/iurl]',
  questionsFreeTitle: 'Есть ли у вас бесплатный тарифный план?',
  questionsFreeText: 'Нет, но у нас есть бесплатный пробный 30-дневный период, который предоставляется сразу после регистрации всем пользователям. ',
  questionsProvidedTitle: 'Есть ли у вас возврат средств?',
  questionsProvidedText: 'Нет, мы не предоставляем функцию возврата.',
  questionsChangeTitle: 'Могу ли я изменить тарифный план?',
  questionsChangeText: 'Да, можете. Для этого необходимо просто оплатить новый тарифный пакет и старая подписка автоматически деактивируется и подключится новая.',

  // blog
  blogViewAll: 'ПОКАЗАТЬ ВСЕ',
  blogRecommendedArticle: 'Рекомендуем прочитать:',

  // post
  postDemoTitle: 'Создай свой первый процесс!',
  postDemoBtnCreate: 'Начать',
  postDemoBtnCreateLink: 'https://doc.corezoid.com/v2/docs/quick-start/',
  postDemoBtnDemo: 'Запросить демо',

  // faq
  faqTitle: 'Частые вопросы (ЧАВО)',
  faqFindText: 'Введите ключевое слово (например: процесс, узел)',
  faqGeneral: 'Общие',
  faqTechnical: 'Технические',
  faqDemoTitle: 'Создайте свое цифровое ядро прямо сейчас!',
  faqDemoBtnCreate: 'Создать первый процесс',
  faqDemoBtnCreateLink: 'https://doc.corezoid.com/v2/docs/quick-start/',
  faqDemoBtnDemo: 'Запросить демо',

  // download
  downloadTitle: 'Скачать бесплатную пробную версию Corezoid',
  downloadSubTitle: 'Создавайте, хостите и запускайте ваши процессы в едином интерфейсе, без кода',
  downloadLinks: '[iurl=https://doc.corezoid.com/v2/docs/quick-start/]Быстрый Старт[/iurl][br][iurl=/tutorials/]Туториалы[/iurl][br][iurl=https://doc.corezoid.com/v2/docs/]Документация[/iurl]',
  downloadLinksTitle: 'Полезные ссылки:',
  downloadLinksMWDocs: 'Инструкция для VMware Fusion',
  downloadLinksMWInstall: 'Инструкция для VMware',
  downloadLinkInstruction: 'Руководство по установке включено',
  downloadSelectTitle: 'Выберите тип инстанса:',
  downloadVersion: 'Версия',
  downloadSize: 'Размер файла',
  downloadTerms: 'Нажав на кнопку "Скачать" вы соглашаетесь[br]с [iurl=/terms-and-conditions/]условиями пользования сервисом[/iurl][br]и [iurl=/terms-and-conditions/]политикой конфиденциальности[/iurl].',
  downloadButtonText: 'Скачать',
  downloadButtonTextGet: 'Получить',
  downloadButtonTextDisabled: 'Скоро',
  downloadDone: '😉[br]Мы только что отправили на ваш email {email} письмо с инструкциями по установке Corezoid на {type}.',
  downloadLink: 'Вы также можете получить их ',
  downloadThank: '[b]Спасибо,что доверяете нам![/b] ',
  downloadHere: 'здесь',
  downloadBack: 'Назад', // 'Вернуться к скачиванию',

  // form
  formSuccess: 'Успех',
  formServerError: 'Ошибка сервера',
  formSuccessMassage: 'Ваше сообщение было отправлено. Мы свяжемся с вами в течение 1 дня.',
  formSuccessMassageUnsubscribe: 'Настройки вашей подписки были обновлены.',
  formSuccessMassageAutoSend: 'Ваше сообщение было отправлено.',
  formLiveDemoTitle: 'Live Demo',
  formLiveDemoSubTitle: 'Хотите узнать как Corezoid Actor Engine может помочь вашему бизнесу?[br][br]Запросите live demo и наш эксперт продемонстрирует возможности Corezoid Actor Engine, а также покажет реальные кейсы применения Corezoid в вашей отрасли.[br][br]Все, что вам нужно - это интернет, PC/Mac и 1 час свободного времени.',
  formLiveDemoContactUs: 'Связаться с нами',
  formLiveDemoButton: 'Запросить live demo',
  formSupportSubTitle: 'Возникли проблемы или вопросы по использованию и изучению Corezoid?[br][br]Наша команда поддержки с радостью ответит на все ваши вопросы![br][br][iurl=/ru/pricing/public-cloud]Прайсинг[/iurl][br][iurl=/ru/terms-and-conditions]Условия и правила[/iurl][br][iurl=/ru/form/live-demo]Запрос демо[/iurl]',
  vacancyTitle: 'Публикуйте вакансии',
  vacancyText: 'Сертифицированные специалисты смогут ознакомиться с вашим предложением и представить свою кандидатуру.',
  formFirstName: 'Имя',
  formName: 'Ваше имя',
  formLastName: 'Фамилия',
  formFullName: 'Полное имя',
  formCvLink: 'Ссылка на резюме',
  formJoinYourTeam: 'Хочу сотрудничать с вами',
  formPosition: 'Желаемая должность',
  formComments: 'Комментарии',
  formEmail: 'Email',
  formCompany: 'Компания',
  formCountry: 'Страна',
  formChanel: 'Выберите канал',
  formChanelOther: 'другое',
  formDate: 'Дата и время',
  formEmployees: 'Кол-во человек',
  formFormat: 'Формат обучения',
  formTitle: 'Заголовок',
  formPhone: 'Номер телефона',
  formCity: 'Город',
  formLevel: 'Уровень',
  formFormatOnline: 'онлайн',
  formFormatOffline: 'оффлайн',
  formInterested: 'Я интересуюсь',
  formInterestedGeneral: 'Общее',
  formInterestedSales: 'Продажи',
  formInterestedBilling: 'Билинг',
  formInterestedMarketing: 'Маркетинг',
  formMessage: 'Ваше сообщение',
  formSend: 'Отправить',
  formSendSubmitVacancy: 'Отправить вакансию',
  formJobTitle: 'Должность',
  formProjectDescription: 'Описание проекта',
  formRequirements: 'Требования к кандидату',
  formLastResponsibilities: 'Задачи и обязанности',
  formBenefits: 'Условия',
  formAdditionalInformation: 'Дополнительная информация',
  formResponsiblePerson: 'Ответственое лицо',
  formCOntactPerson: 'Контактное лицо',

  // extended-trial
  extendedTrialTitle: 'Запросить продление тестового периода',
  extendedTrialSubTitle: 'Не хватило 30 дней для изучения Corezoid? Без проблем! Мы с радостью продлим Ваш тестовый период по запросу.',
  extendedTrialFormMessage: 'Пожалуйста, опишите почему Вам не хватило 30-дневного пробного периода',
  extendedTrialFormSubText: 'Эта информация будет использована для улучшения сервиса',
  extendedTrialFormButtonText: 'Запросить продление',

  // benefits
  benefitsManagersSpeed: 'Скорость запуска новых продуктов',
  benefitsManagersSpeedText: 'Создавайте процессы за считанные минуты без привлечения разработчиков благодаря интуитивно понятному визуальному интерфейсу Corezoid вы можете.',
  benefitsManagersDashboards: 'Онлайн-дашборды',
  benefitsManagersDashboardsText: 'Агрегируйте всю важную информацию в одном месте и держите руку на пульсе вашего бизнеса в режиме реального времени благодаря встроенным онлайн-дашбордам. Держите руку на пульсе вашего бизнеса благодаря встроенным онлайн-дашбордам, которые агрегируют всю важную информацию в одном месте.',
  benefitsManagersEsc: 'Эскалации в режиме реального времени',
  benefitsManagersEscText: 'Хотите получать уведомления о результатах активности или аномальных явлениях? Corezoid позволяет в несколько кликов настраивать правила уведомлений о непредвиденных ситуациях.',
  benefitsManagersCurve: 'Низкий порог входа',
  benefitsManagersCurveText: 'Все, что нужно для начала работы с Corezoid - выучить 15 логических блоков и научиться комбинировать их. Обычно это занимает до 3 дней.',
  benefitsManagersProto: 'Быстрое прототипирование',
  benefitsManagersProtoText: 'Благодаря низкому порогу входа, Corezoid позволяет быстро создавать прототипы ваших продуктов, запускать их на рынок,  получать обратную связь и итеративно улучшать.',
  benefitsManagersBusiness: 'Непрерывность бизнеса',
  benefitsManagersBusinessText: 'Благодаря визуальному программированию, Corezoid позволяют снизить зависимость от конкретных исполнителей и увеличить устойчивость вашего бизнеса к изменяющейся внешней среде и текучести кадров.',
  benefitsManagersEngagement: 'Вовлеченность',
  benefitsManagersEngagementText: 'Благодаря низкому порогу входа, сотрудники любого уровня и квалификации могут создавать процессы в Corezoid и влиять на бизнес.',
  benefitsManagersCollaboration: 'Совместная работа',
  benefitsManagersCollaborationText: 'Благодаря шейрингу процессов и управлению доступами, Corezoid позволяет организовать совместную работу над продуктом, раскрыв весь потенциал коллективного творчества и опыта.',
  benefitsManagersTransparency: 'Прозрачность',
  benefitsManagersTransparencyText: 'Corezoid позволяет собрать все процессы компании в одном месте, благодаря чему каждый член команды может получить доступ и управлять любыми процессами в рамках выданных полномочий.',
  benefitsManagersSecurity: 'Безопасность',
  benefitsManagersSecurityText: 'Corezoid был рожден в банковской отрасли, где вопрос безопасности - критически важен. Corezoid успешно прошел ряд аудитов от таких компаний как Avon, WU, METRO, AWS и соответствует требованиям PCI DSS и ISO27001. Также Corezoid является рекомендованным решением для финансовой отрасли в рамках программы AWS Financial Services Competency.',
  benefitsManagersHigh: 'Производительность',
  benefitsManagersHighText: 'Corezoid позволяет создавать сервисы, которые без проблем выдерживают нагрузку в сотни тысяч запросов в секунду. В работе с ведущими мировыми компаниями мы зарекомендовали себя как надежное решения для высоконагруженных систем.',
  benefitsManagersUltra: 'Масштабируемость',
  benefitsManagersUltraText: 'Corezoid обеспечивает нативное автоматическое горизонтальное масштабирование, что позволит вам не содержать штат devops и разработчиков для решения подобных вопросов. Концентрируйтесь на бизнесе, а не проблемах масштабирования.',
  benefitsDevelopersProcess: 'Редактор процессов',
  benefitsDevelopersProcessText: 'Интуитивный drap&drop редактор процессов позволяет создавать алгоритмы любой сложности за считанные минуты.',
  benefitsDevelopersHosting: 'Хостинг и запуск процессов',
  benefitsDevelopersHostingText: 'С помощью Corezoid вы можете создавать, хостить и запускать любые процессы всего в несколько кликов в едином интерфейсе.',
  benefitsDevelopersDebug: 'Режим дебага',
  benefitsDevelopersDebugText: 'Встроенный режим дебага позволит просмотреть каждый пройденный заявкой шаг процесса и запросто исправить возможные ошибки.',
  benefitsDevelopersDashboards: 'Онлайн дашборды',
  benefitsDevelopersDashboardsText: 'Встроенные полностью настраиваемые онлайн-дашборды помогут вам держать руку на пульсе ваших процессов, чтобы принимать важные решения в режиме реального времени.',
  benefitsDevelopersEscalations: 'Онлайн эскалации',
  benefitsDevelopersEscalationsText: 'С помощью Corezoid вы можете настраивать правила отправки уведомлений о сбоях и увеличения/уменьшения нагрузки на любом шаге вашего процесса.',
  benefitsDevelopersPopular: 'Работайте с любыми API',
  benefitsDevelopersPopularText: 'Corezoid поддерживает все популярные форматы API: JSON, XML, NVP. Это означает, что вы сможете запросто интегрировать ваши процессы с 99% API.',
  benefitsDevelopersApi: 'Процесс как API',
  benefitsDevelopersApiText: 'Каждый процесс в Corezoid может быть использован как API. Таким образом вы можете предоставить вашим коллегам или партнерам доступ к вашим процессам с помощью прямой ссылки или авторизации на базе токенов.',
  benefitsDevelopersFrameworks: 'Шаблоны процессов',
  benefitsDevelopersFrameworksText: 'Каждый процесс или набор процессов может быть использован как шаблон, что позволяем вам переиспользовать готовые наработки в других проектах и изобретать колесо.',
  benefitsDevelopersErlang: 'GIT Call',
  benefitsDevelopersErlangText: 'Если вашему процессу недостаточно функциональности базовых узлов, вы всегда можете использовать узел GIT Call для подключения кода из git-репозитория, написанного на любом языке программирования.',
  benefitsDevelopersEvent: 'Событийная архитектура',
  benefitsDevelopersEventText: 'Такие встроенные технологии, как асинхронная система очередей, параллельная обработка событий и вебхуки открывают для вашего бизнеса новые возможности для решения тактических и стратегических задач.',
  benefitsDevelopersParallel: 'Параллельная разработка',
  benefitsDevelopersParallelText: 'Архитектура Corezoid позволяет разделить ветки разработки путем разбиения большого проекта на мелкие управляемые компоненты, которые состоят из множества процессов. Распараллелив разработку отдельных компонентов вы сможете ускорить разработку всего проекта.',
  benefitsDevelopersManagement: 'Управление правами доступа',
  benefitsDevelopersManagementText: 'Система управления правами Corezoid позволяет настраивать 3 уровня доступов: просмотр процесса, изменение процесса, создание задач в процессах. Таким образом каждый член команды может получить правильный набор прав для выполнения своих обязанностей.',
  benefitsDevelopersSecurity: 'Безопасность',
  benefitsDevelopersSecurityText: 'Corezoid был рожден в банковской отрасли, где вопрос безопасности - критически важен. Corezoid успешно прошел ряд аудитов от таких компаний как Avon, WU, METRO, AWS и соответствует требованиям PCI DSS и ISO27001. Также Corezoid является рекомендованным решением для финансовой отрасли в рамках программы AWS Financial Services Competency.',
  benefitsDevelopersHigh: 'Производительность',
  benefitsDevelopersHighText: 'В отличии от BPM, Corezoid был создан для работы с высоконагруженными проектами и индустриями. Это единственный инструмент, который позволяет создавать, хостить и запускать процессы в режиме реального времени, которые способны выдерживать тысячи запросов в секунду с минимальной затратой ресурсов.',
  benefitsDevelopersUltra: 'Масштабируемость',
  benefitsDevelopersUltraText: 'Архитектура Corezoid была построена для быстрого горизонтального масштабирования с минимальным количеством усилий. Вы можете масштабировать и клаастеризировать каждый отдельной взятый компонент Corezoid для достижения максимальной производительности.',

  // aws
  awsHeaderTitle: 'Преимущества работы с AWS',
  awsHeaderText: 'AWS предоставляет безопасную, гибкую и инновационную среду для развертывания проектов на основе Corezoid.',
  awsRoute53: 'Amazon Route53',
  awsRoute53Description: 'Перенос доменных имен в [iurl=https://aws.amazon.com/ru/route53/]Amazon Route53[/iurl] позволяет легко изолировать и распределять трафик пользователей из разных стран с помощью политики маршрутизации на основании геолокации.',
  awsElasticLoadBalancing: 'Elastic Load Balancing',
  awsElasticLoadBalancingDescription: 'Corezoid использует [iurl=https://aws.amazon.com/elasticloadbalancing/]Elastic Load Balancing[/iurl], который автоматически распределяет входящий трафик между несколькими инстансами [iurl=https://aws.amazon.com/ec2/]Amazon EC2 instances[/iurl].',
  awsCloudTrial: 'Amazon CloudTrail',
  awsCloudTrailDescription: '[iurl=https://aws.amazon.com/cloudtrail/]AWS CloudTrail[/iurl] и AWS Config помогают быстро отслеживать любые изменения в продакшн среде и поддерживать высокий уровень безопасности.',
  awsCloudFormation: 'AWS CloudFormation',
  awsCloudFormationDescription: 'Используя [iurl=https://aws.amazon.com/cloudformation/]AWS CloudFormation[/iurl],  мы значительно сокращаем операционные расходы на управление инфраструктурой, поскольку все ключевые элементы определены в виде кода. Разработчики могут легко запускать выделенные кластеры для тестирования новых функций.',
  awsAutoScaling: 'AWS Auto Scaling',
  awsAutoScalingDescription: 'Используя [iurl=https://aws.amazon.com/autoscaling/]Auto Scaling[/iurl], мы улучшаем качество и финансовую эффективность работы отдельных компонентов, таких как служба выполнения пользовательского кода (Erlang, JS) в отдельных кластерах процессов. Вы платите только за вычислительные ресурсы и ресурсы хранения, которые используются сервисами.',

  // footer
  footerStarted: 'GET STARTED',
  footerStartedContext: 'Learn how to use Corezoid with our free tutorials, dozens of sample processes and documentation.',
  footerFaq: 'FAQ',
  footerTutorials: 'Tutorials',
  footerDocumentation: 'Documentation',
  footerSampleProcesses: 'Sample Processes',
  footerDemo: 'REQUEST A LIVE DEMO',
  footerDemoContext: 'Request a live demo and let us show you the power of Corezoid Actor Engine! All you need is an internet connection and 30 minutes of free time.',
  footerContact: 'CONTACT US',
  footerContactContext: 'Contact us and we’ll be glad to answer all your questions and provide you with additional information about Corezoid.',

  footerLinkCorezoidProcesEngine: 'Corezoid Actor Engine',
  footerLinkPricing: 'Прайсинг',
  footerLinkResources: 'Обучение',
  footerLinkQuickStart: 'Быстрый старт',
  footerLinkTutorials: 'Туториалы',
  footerLinkFaq: 'ЧАВО',
  footerLinkCommunity: 'Сообщество',
  footerLinkWebinars: 'Вебинары',
  footerLinkDocs: 'Документация',
  footerLinkWorkshops: 'Воркшопы',
  footerLinkCertification: 'Сертификация',
  footerLinkCompany: 'Компания',
  footerLinkAbout: 'О нас',
  footerLinkBlog: 'Новости',
  footerLinkJobs: 'Вакансии',
  footerLinkMedia: 'Медиа',
  footerLinkPrivacyTerms: 'Условия и правила',
  footerLinkContactUs: 'Связаться с нами',
  footerLinkDownload: 'Для скачивания',
  footerLinkPresentations: 'Презентация',
  footerLinkWhitepapers: 'White papers',
  footerLinkVMware: 'Corezoid для VMware',
  footerLinkMarketingKit: 'Маркетинговые материалы',
  footerLinkSubscribe: 'Подпишитесь на обновления',
  footerTextSubscribe: 'Только полезные новости, статьи и материалы о Corezoid.',
  footerInputSubscribe: 'Email',
  footerButtonSubscribe: 'Подписаться',
  footerDoneSubscribe: 'Спасибо!',
  langen: 'English',
  langru: 'Русский',
};
