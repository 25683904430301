export default {
  technology: 'Technology',
  solutions: 'Solutions',
  caseStudies: 'Case Studies',
  pricing: 'Pricing',
  learn: 'Learn',
  company: 'Company',
  login: 'Login',
  signUp: 'Sign up',
  signIn: 'Sign in',
  continue: 'Continue to Corezoid',

  // header
  headerProduct: 'Products',
  headerProductProcessEngine: 'Actor Engine',
  headerProductBotPlatform: 'Communications Orchestrator',
  headerSolutions: 'Solutions',
  headerSolutionsSoftware: 'Software',
  headerSolutionsApi: 'API',
  headerSolutionsCode: 'Code',
  headerSolutionsProcesses: 'Processes',
  headerSolutionsIot: 'IoT',
  headerSolutionsCommunication: 'Communication',
  headerSolutionsEvents: 'Events',
  headerSolutionsKnowledge: 'Knowledge',
  headerSolutionsPeople: 'People',
  headerSolutionsCloud: 'Cloud',
  headerCaseStudies: 'Case Studies',
  headerPricing: 'Pricing',
  headerLearn: 'Learn',
  headerCompany: 'Company',
  headerCompanyAbout: 'About us',
  headerCompanyNews: 'News',
  headerCompanyPartnership: 'Partnership',
  headerCompanyJobs: 'Jobs',

  // news Header
  newsSigmaSoftware: 'Free online webinar: “Digital Transformation for Banks: Trends and Challenges”. Moderator: Sergii Danylenko, VP at Middleware.',

  // 404
  '404Title': 'OOPS: PAGE NOT FOUND',
  '404SubTitle': 'WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND',

  // main Page
  // mainTitle: 'Want to be a tech tycoon?',
  mainTitle: 'How to start a digital transformation in a company?',
  mainSubTitle: 'The path to digital transformation starts with the first process...',
  mainBtnOnline: 'Free cloud trial',
  mainBtnDownload: 'Download Corezoid',
  // mainBtnDownload: 'Free Download',
  // mainBtnDownload: 'Free On-premises Trial',
  // mainDemoTitle: 'Build your Digital Core right now!',
  // mainDemoBtnCreate: 'Create first process',
  mainDemoBtnDemo: 'Request demo',
  mainContentSoftwareTitle: 'Master the software jungle',
  mainContentSoftwareSubTitle: 'Every company has dozens of software solutions from various vendors. Corezoid can become your zookeeper for them, uniting diverse species into a single effectively managed software ecosystem.',
  mainContentSoftwareBtnTitle: 'Learn more',
  mainContentApiTitle: 'Manage external APIs and create your own',
  mainContentApiSubTitle: 'There are millions of APIs, and each one has its unique protocol. Corezoid is a universal metalanguage that allows fast creation and management of existing APIs using a single interface.',
  mainContentApiBtnTitle: 'Learn more',
  mainContentCodeTitle: 'Use any public code in the world, written in any language',
  mainContentCodeSubTitle: 'Why reinvent the wheel, when the code you need is already out there. Corezoid helps you find the required code in any git-repository and launch it as a part of your project.',
  mainContentCodeBtnTitle: 'Learn more',
  mainContentProcessesTitle: 'Focus on the process, not the code',
  mainContentProcessesSubTitle: 'Move your business logic from legacy code to Corezoid processes and speed up the creation of new products while lowering support cost.',
  mainContentProcessesBtnTitle: 'Learn more',
  mainContentIotTitle: 'Control the “IoT jungle”',
  mainContentIotSubTitle: 'The world is filled with hundreds of thousands of devices from myriad of vendors, each having their own protocol. Corezoid helps merge this “hardware zoo” into a unified ecosystem and enables you to manage it effectively.',
  mainContentIotBtnTitle: 'Learn more',
  mainContentCommunicationTitle: 'Control and automate communications',
  mainContentCommunicationSubTitle: 'Customers want to communicate with businesses via dozens of channels. Corezoid helps you manage all these channels and automates your communications with bots.',
  mainContentCommunicationBtnTitle: 'Learn more',
  mainContentEventsTitle: 'Keep everything under control!',
  mainContentEventsSubTitle: 'Lack of control is one of the key problems facing companies. Control.events helps you effectively monitor and manage events that occur both inside and outside your company. Control any type of events: projects and tasks within the company, meetings on calendars, contract stages with partners and contractors, and much more. Thanks to Corezoid, you can integrate Control.events with an unlimited number of event sources.',
  mainContentEventsBtnTitle: 'Learn more',
  mainContentKnowledgeTitle: 'Manage company information and knowledge',
  mainContentKnowledgeSubTitle: 'Company information and knowledge are hidden in data and stored in dozens of separate softwares with different formats. Cloud expert system DeepMemo will help to connect data from different sources into a single repository, extract information and knowledge from raw data to make better and more effective decisions: from super personalization to anti-fraud.',
  mainContentKnowledgeBtnTitle: 'Learn more',
  mainContentPeopleTitle: 'Manage leads, customers, employees, partners, suppliers...',
  mainContentPeopleSubTitle: 'Transfer your people management technology to Corezoid: create sales funnels, manage customer life cycles, suppliers and inventory; track fulfillment and non-fulfillment of KPIs, as well as employee tasks.',
  mainContentPeopleBtnTitle: 'Learn more',
  mainContentCloudTitle: 'Serverless computing',
  mainContentCloudSubTitle: '“Banking is essential, banks are not” (c) Bill Gates[br][br] Admit it, you need the results of computing, not the process. Create and run algorithms of any complexity on Corezoid cloud that is secure and autoscales.',
  mainContentCloudBtnTitle: 'Learn more',
  mainContentDemoBtnTitle: 'Learn more',
  mainContentCoreTitle: 'Build your Digital Core right now!',
  mainContentCoreBtnTitle: 'GET STARTED',

  // benefits
  benefitsTitle: 'Corezoid Benefits',
  benefitsSubTitle: 'Explore the power of Corezoid for each team member',
  benefitsManagers: 'Managers',
  benefitsDevelopers: 'Developers',

  // product
  productHeaderTitle: 'Corezoid Actor Engine Anatomy',
  productHeaderSubTitle: 'Explore the power of Corezoid for each team member',
  productAssembleTitle: 'Assemble',
  productAssembleSubTitle: 'Process Editor',
  productAssembleListEditTitle: 'Edit mode',
  productAssembleListEditText: 'Easily build processes via intuitive drag & drop editor.',
  productAssembleListViewTitle: 'View mode',
  productAssembleListViewText: 'Control the flow of execution of your processes in real-time',
  productAssembleListDebugTitle: 'Debug mode',
  productAssembleListDebugText: 'Debug each step of a process and quickly find and fix bugs',
  productAssembleConsHostTitle: 'Process hosting',
  productAssembleConsHostText: 'Corezoid Actor Engine allows you to deploy processes in just 1 click in our public cloud environment. We also provide private cloud and on-premises hosting options. [iurl=/new-pricing/public-cloud/]See all hosting options[/iurl]',
  productAssembleConsExecutionTitle: 'Process execution',
  productAssembleConsExecutionText: 'Once you’ve created and hosted processes you can execute and debug them in Process Editor without rebooting the whole system.',
  here: 'here',
  productOrganizeTitle: 'Organize',
  productOrganizeSubTitle: 'Process Browser',
  productOrganizeListProjectsTitle: 'Projects',
  productOrganizeListProjectsText: 'Isolated environments help manage project development',
  productOrganizeListComponentsTitle: 'Components',
  productOrganizeListComponentsText: 'Develop and update every part separately',
  productOrganizeListProcessesTitle: 'Processes',
  productOrganizeListProcessesText: 'Each component consists of a set of independent processes.',
  productShareTitle: 'Share',
  productShareSubTitle: 'Users and groups',
  productShareListUsersTitle: 'Users',
  productShareListUsersText: 'Share access to your projects, components and processes with other users',
  productShareListGroupsTitle: 'Groups',
  productShareListGroupsText: 'Create user groups to simplify corporate access rights management',
  productShareListApiTitle: 'API keys',
  productShareListApiText: 'Provide access to your projects to third-party developers, clients and partners',
  productControlTitle: 'Control',
  productControlSubTitle: 'Access rights',
  productControlListViewTitle: 'View',
  productControlListViewText: 'Look through the project structure and process execution flow',
  productControlListModifyTitle: 'Modify',
  productControlListModifyText: 'Create and control processes and projects',
  productControlListTaskTitle: 'Task management',
  productControlListTaskText: 'Create, update and delete tasks in shared processes.',
  productAdditionsListCollaborationTitle: 'Collaboration',
  productAdditionsListCollaborationText: 'Built-in features for collaboration like process sharing and permissions management unleash the strength of cumulative creativity and experience.',
  productAdditionsListTransparencyTitle: 'Transparency',
  productAdditionsListTransparencyText: 'Corezoid collects all company processes in one place, so every team member can get access and manage processes according to given permissions.',
  productAnalizeTitle: 'Analyze',
  productAnalizeSubTitle: 'Real-time Dashboards',
  productAnalizeListCustomizableTitle: 'Customizable dashboards',
  productAnalizeListCustomizableText: 'Thanks to customizable dashboards you can feel the heartbeat of your processes and make valuable business decisions in real time.',
  productAnalizeListActivityTitle: 'Activity Monitoring',
  productAnalizeListActivityText: 'Analyze quantity of tasks, state changes and traffic of your processes to predict and optimize your spending.',
  productDemoTitle: 'Build your first process right now!',
  productDemoButton: 'GET STARTED',

  // lending
  registration: 'Get started',
  registrationSubText: 'Free 30-day trial • No credit card required',
  orderWorkshops: 'Request workshop',
  orderDemo: 'Request demo',
  startFreeDemo: 'START FREE TRIAL',

  // bot-platform
  botPlatformTitle: 'Communications Orchestrator ',
  botPlatformSubTitle: 'Create, manage and host your messenger communications without any programming skills within a single Corezoid UI.',
  botPlatformMessengerTitle: 'All popular instant messengers and communication channels in one place',
  botPlatformMessengerSubTitle: 'Clients want to keep in touch with your business via instant messengers. Start interacting with your customers via all the popular instant messengers right now by using the Corezoid Communications Orchestrator!',
  botPlatformLogicTitle: 'One business logic for all instant messengers',
  botPlatformLogicSubTitle: 'Tired of creating business logic for each messenger separately? Now you can quickly create versatile algorithms that work for all instant messengers.',
  botPlatformBotTitle: 'Automation of communication using bots',
  botPlatformBotSubTitle: 'From 50% to 90% of the questions that our customers answer are standard ones, and dealing with them can be automated. Automate your communication with customers using Communications Orchestrator. Increase communication efficiency and reduce management costs.',
  botPlatformContactCenterTitle: 'Omni Channel Contact Center',
  botPlatformContactCenterSubTitle: 'Thanks to the Communications Orchestrator’s native integration with Sender, you get an operator’s workplace, using which you can immediately connect operators to customers in all the popular instant messengers.',
  botPlatformIntegrationTitle: 'Unlimited integration options',
  botPlatformIntegrationSubTitle: 'Extend your platform functionality with an unlimited integration of external services. The only thing required for integration with services is their API availability.',
  botPlatformAnalyticTitle: 'Analytics (real-time dashboards)',
  botPlatformAnalyticSubTitle: 'With Communications Orchestrator, you can create your own online analytics dashboards and select the key metrics that you want to track.',
  botPlatformEnterpriseTitle: 'Enterprise-grade Features',
  botPlatformEnterpriseSecurityTitle: 'Security',
  botPlatformEnterpriseSecuritySubTitle: 'Corezoid was born in fintech, where security is crucial. We’ve been audited by the world’s leading companies like Western Union, VISA and METRO to match the highest security standards. Corezoid is PCI DSS and ISO 27001 compliant.',
  botPlatformEnterprisePerformanceTitle: 'Performance',
  botPlatformEnterprisePerformanceSubTitle: 'Corezoid was built to be used for high-load projects and industries. It’s the only tool that allows you to build, host and run processes in real-time and handle thousands of concurrent requests with little resource involvement.',
  botPlatformEnterpriseScaleTitle: 'Scalability',
  botPlatformEnterpriseScaleSubTitle: 'Corezoid architecture was built around ultra fast horizontal scalability for minimum efforts. You can separately scale each of Corezoid\'s component for efficiency or clusterize Corezoid instances for maximum performance.',
  botPlatformBotsTitle: 'See how customers use Communications Orchestrator ',
  botPlatformStartTitle: 'Start using Communications Orchestrator ',
  botPlatformSignIn: 'Register at Corezoid.com',
  botPlatformTutorial: 'Complete the Communications Orchestrator  tutorial',
  botPlatformStartBots: 'Start building bots without programmers',

  // Apple Messages for Business
  abcTitle: 'Apple Messages for Business',
  abcSubTitle: 'A whole new way to talk with your customers',
  abcText: 'Now your customers can get help, schedule appointments, and even make purchases right in Messages on iPhone, iPad, Mac and Apple Watch.',
  abcButtonText: 'Request a demo',
  abcFeaturesTitle: 'Apple Messages for Business Features',
  abcFeaturesBuiltInMessagesTitle: 'Built-in Messages application',
  abcFeaturesBuiltInMessagesText: 'The Apple Messages app is built into iOS, which means that iPhone and iPad users aren’t required to download and install an app in order to use Apple Messages for Business. ',
  abcFeaturesPayTitle: 'Easy payments with Apple Pay',
  abcFeaturesPayText: 'Users can pay for your goods and services directly from Apple Messages for Business, without having to go to external sites, enter card data, etc.',
  abcFeaturesOperatorTitle: 'Quick online support',
  abcFeaturesOperatorText: 'Using Corezoid-based Communications Orchestrator and Sender Communication Engine you can instantly connect customers from Apple Messages for Business with your live agents.',
  abcFeaturesMediaTitle: 'Rich images and videos',
  abcFeaturesMediaText: 'Increase customer engagement in communication with your company with pictures and videos.',
  abcFeaturesAppointmentsTitle: 'Schedule appointments',
  abcFeaturesAppointmentsText: 'The built-in Apple Messages for Business date and time picker function allows you to provide your customers with a convenient tool for noting a meeting, appointment or event.',
  abcFeaturesExtensionCallTitle: 'App extension call',
  abcFeaturesExtensionCallText: 'In addition to the above great functions, you can always call any function from your iOS application to expand the abilities of Apple Messages for Business',
  abcEnterEmailPlaceholder: 'Enter your business email',
  abcEnterEmailButton: 'Request a demo',
  abcFormTitle: 'Start serving customers in Apple Messages for Business',

  // botPlatform viber
  botPlatformViberTitle: 'Start serving customers in the messenger',
  botPlatformViberSubTitle: 'Thanks to the Viber capabilities, you can not only communicate with your customers, but also start selling products and services in the messenger',
  botPlatformViberButtonText: 'Start now',
  botPlatformViberKeyboardTitle: 'Keyboards',
  botPlatformViberKeyboardSubTitle: 'Create a set of your own unique keyboards that will allow users to control your bot',
  botPlatformViberSliderTitle: 'Carousels',
  botPlatformViberSliderSubTitle: 'Your customers will be able to browse the catalog of goods and services using the built-in customizable carousels',
  botPlatformViberContactCenterTitle: 'Contact Center',
  botPlatformViberContactCenterSubTitle: 'With the native integration of Communications Orchestrator and Sender, you can instantly connect operators to serve customers from Viber',
  botPlatformViberBulkMailingTitle: 'Bulk Mailing',
  botPlatformViberBulkMailingSubTitle: 'Send mass mailings using the built-in newsletter module in Communications Orchestrator',
  botPlatformViberSharingPhoneTitle: 'Phone number Sharing',
  botPlatformViberSharingPhoneSubTitle: 'Authorize your users by phone number without SMS verification',
  botPlatformViberSharingLocationTitle: 'Location Sharing',
  botPlatformViberSharingLocationSubTitle: 'Request a user’s location to create services, connected with geo position',
  botPlatformViberRichMediaTitle: 'Rich Media',
  botPlatformViberRichMediaSubTitle: 'Exchange with your clients all file formats: audio, video, pictures',
  botPlatformViberStikersTitle: 'Stickers',
  botPlatformViberStikersSubTitle: 'Speak the same language with your customers by sending Viber stickers',
  botPlatformViberDeepLinkTitle: 'Deep Link',
  botPlatformViberDeepLinkSubTitle: 'Create dynamic links that can convey a communication context, data, and even trigger bot functions',
  botPlatformViberDeepLinkLink: 'Order food delivery',
  botPlatformViberPaymentsTitle: 'Payments',
  botPlatformViberPaymentsSubTitle: 'Users can instantly pay for their goods using Apple/Google Pay without leaving the chat',
  botPlatformViberClientsTitle: 'Start using Viber for Business today',
  botPlatformViberFormTitle: 'Start serving customers in the Viber',

  // botPlatform google business messages
  botPlatformGMBTitle: 'Google Business Messages',
  botPlatformGMBSubTitle: 'Open a new sales channel with Google Business Messages: showcase your products and services with carousels, sell, provide operator support, and automate service with bots',
  botPlatformGMBPointsTitle: 'Combine entry points',
  botPlatformGMBPointsSubTitle: 'Start communicating with customers who come from Google Maps, search results, website, mobile application, or even offline',
  botPlatformGMBserviceTitle: 'Personalized service',
  botPlatformGMBserviceSubTitle: 'Personalize customer service based on entry point',
  botPlatformGMBkeyboardsTitle: 'Keyboards',
  botPlatformGMBkeyboardsSubTitle: 'Make clients interaction with your business as convenient as possible with a custom keyboards',
  botPlatformGMBCarouselsTitle: 'Carousels',
  botPlatformGMBcaruselsSubTitle: 'Show customers your products and services in a chat in the messenger using customizable carousels',
  botPlatformGMBCommunicationTitle: 'Communication center',
  botPlatformGMBCommunicationSubTitle: 'Use the built-in functionality of the Communications Orchestrator to connect operators to solve clients issues in the chat',
  botPlatformGMBRichCardTitle: 'Rich Card',
  botPlatformGMBRichCardSubTitle: 'Send receipts, e-tickets or vouchers directly to the chat using images or a QR code',
  botPlatformGMBFormTitle: 'Start serving customers in Google Business Messages',

  // botPlatform telegram
  botPlatformTelegramTitle: 'Get closer to your customers',
  botPlatformTelegramSubTitle: 'Using Telegram, you can not only chat with customers, but also create bots to automate the communication and sale of your goods and services.',
  botPlatformTelegramButtonText: 'Try now',
  botPlatformTelegramKeyboardTitle: 'Keyboard',
  botPlatformTelegramKeyboardSubTitle: 'Create your own keyboards allowing customers to control your bot',
  botPlatformTelegramInlineKeyboardTitle: 'Inline-keyboards',
  botPlatformTelegramInlineKeyboardSubTitle: 'Create dynamic inline-keyboards and add them to text messages to expand the capabilities of your bot',
  botPlatformTelegramContactCenterTitle: 'Contact Center',
  botPlatformTelegramContactCenterSubTitle: 'Thanks to the native integration of Communications Orchestrator and Sender, you can instantly connect operators to serve customers within Telegram',
  botPlatformTelegramBulkMailingTitle: 'Broadcast messages',
  botPlatformTelegramBulkMailingSubTitle: 'Send news about your services and products or create newsletters using the built-in broadcast module with Communications Orchestrator',
  botPlatformTelegramPayTitle: 'Payments',
  botPlatformTelegramPaySubTitle: 'Users can pay for goods and services with just a few clicks, without the need to enter payment details',
  botPlatformTelegramSharingPhoneTitle: 'Share phone',
  botPlatformTelegramSharingPhoneSubTitle: 'Authorize your users by phone number without the need for SMS verification',
  botPlatformTelegramSharingLocationTitle: 'Location Sharing',
  botPlatformTelegramSharingLocationSubTitle: 'Get the user’s location for geo services services',
  botPlatformTelegramRichMediaTitle: 'Rich Media',
  botPlatformTelegramRichMediaSubTitle: 'Exchange any file formats with your clients in chat: audio, video, pictures',
  botPlatformTelegramDeepLinkTitle: 'Deep Link',
  botPlatformTelegramDeepLinkSubTitle: 'Send communication context, data, and even launch hidden bot functions using dynamic links',
  botPlatformTelegramDeepLinkLink: 'Order food delivery',
  botPlatformTelegramClientsTitle: 'Start using Telegram for Business today',
  botPlatformTelegramFormTitle: 'Start serving customers in the Telegram',

  // botPlatform facebook
  botPlatformFacebookTitle: 'Provide a new sales and customer service channel with Facebook Messenger',
  botPlatformFacebookSubTitle: 'Start serving customers on Facebook Messenger with agents; Automate customer support using robots; sell goods and services using catalogs and invoices',
  botPlatformFacebookButtonText: 'Try now',
  botPlatformFacebookKeyboardTitle: 'Keyboards',
  botPlatformFacebookKeyboardSubTitle: 'Create useful keyboards to help customers managing your bot',
  botPlatformFacebookTemplatesTitle: 'Templates',
  botPlatformFacebookTemplatesSubTitle: 'Use а set of built-in message templates to sell goods and services, send documents, airline tickets, checks, news or personalized offers',
  botPlatformFacebookContactCenterTitle: 'Contact Center',
  botPlatformFacebookContactCenterSubTitle: 'By default, you get a built-in operator chat, allowing you to requests from Facebook Messenger',
  botPlatformFacebookProfileTitle: 'Access to client’s Facebook profile',
  botPlatformFacebookProfileSubTitle: 'Get data from the client profile to personalize communication and improve the quality of service',
  botPlatformFacebookDeepLinkTitle: 'Deep Link',
  botPlatformFacebookDeepLinkSubTitle: 'Launch bot functions or pass a communication context using dynamic links',
  botPlatformFacebookDeepLinkLink: 'Order food delivery',
  botPlatformFacebookRichMediaTitle: 'Rich Media',
  botPlatformFacebookRichMediaSubTitle: 'Provide an answer to the client with a convenient format depending on the communication context: audio, video, pictures or files',
  botPlatformFacebookClientsTitle: 'Start using Facebook Messenger for Business today',
  botPlatformFacebookFormTitle: 'Start serving customers in the Facebook Messenger',

  // software
  softwareHeaderTitle: 'Master the software jungle',
  softwareHeaderSubTitle: 'Every company has dozens of software solutions from various vendors. Corezoid can become your zookeeper for them, uniting diverse species into a single effectively managed software ecosystem.',
  softwareContentZooTitle: 'Unite the software jungle within a single interface',
  softwareContentZooSubTitle: 'Billing, calendar, bookkeeping, mobile apps, messengers, bots... the list goes on. Corezoid helps you organize the interactions between these programs, and allows you to concentrate on business instead of software integration.',
  softwareContentSoftTitle: 'Expand your software capabilities',
  softwareContentSoftSubTitle: 'It takes significantly longer to develop new software than to sign new deals. Corezoid prevents business development from being blocked by your software\'s capabilities. Create new features instantly with Corezoid.',
  softwareContentDashTitle: 'Manage software using online dashboards',
  softwareContentDashSubTitle: 'Control and optimize your software using Corezoid online dashboards that provide comprehensive process information at any point in real time.',
  softwareContentEscalTitle: 'Manage events and configure escalations',
  softwareContentEscalSubTitle: 'Corezoid helps you manage the stream of events triggered by your software and allows you to configure escalations in real time.',
  softwareContentTimeTitle: 'Ease of support and modification',
  softwareContentTimeSubTitle: 'Due to its low entry threshold and intuitive interface, Corezoid allows you to create, change and support without any hard-coding software process logic.',

  // api
  apiHeaderTitle: 'Manage external APIs and create your own',
  apiHeaderSubTitle: 'There are millions of APIs, and each one has its unique protocol. Corezoid is a universal metalanguage that allows fast creation and management of existing APIs using a single interface.',
  apiContentSingleTitle: 'United API management interface',
  apiContentSingleSubTitle: 'Products consist of tens of hundreds of internal and external APIs. Corezoid allows you to create an all-purpose API protocol and merge all of your API management into a single interface.',
  apiContentAutoTitle: 'Create API automatically',
  apiContentAutoSubTitle: 'Every process created within Corezoid is an API that can be added to your product.',
  apiContentWebhooksTitle: 'Webhooks',
  apiContentWebhooksSubTitle: 'Use Corezoid to accept events and configure triggers. Triggers direct events to their handler - a Corezoid process, software or an API.',
  apiContentPerformanceTitle: 'Performance',
  apiContentPerformanceSubTitle: 'Due to its fault-tolerant architecture and automatic horizontal scaling, Corezoid can easily handle tens of thousands of requests per second.',
  apiContentSecurityTitle: 'Security',
  apiContentSecuritySubTitle: 'An integrated system of access rights management assists you in setting up secure interactions between your processes and external or internal APIs and services.',

  // processes
  processesHeaderTitle: 'Focus on the process, not the code',
  processesHeaderSubTitle: 'Move your business logic from legacy code to Corezoid processes and speed up the creation of new products while lowering support cost.',
  processesHeaderButton: 'Request Demo',
  processesContentTimeTitle: 'Lower time-to-market down to 0',
  processesContentTimeSubTitle: 'Corezoid spearheads a novel approach to development, which requires lower specialists, reduces iteration time, and lowers costs and risks',
  processesContentDashTitle: 'Real-time dashboards',
  processesContentDashSubTitle: 'Integrated dashboards allow you to track the state of your process at any point in real time and make management decisions that improve efficiency and optimize your business.',
  processesContentTestTitle: 'A/B testing standards',
  processesContentTestSubTitle: 'Test and improve versions of your processes within a few hours without the need for developers.',
  processesContentProtoTitle: 'Prototyping tool',
  processesContentProtoSubTitle: 'Corezoid\'s low entry threshold enables rapid prototyping and reduces time to market.',

  // code
  codeHeaderTitle: 'Use any public code in the world, written in any language',
  codeHeaderSubTitle: 'Why reinvent the wheel, when the code you need is already out there. Corezoid helps you find the required code in any git-repository and launch it as a part of your project.',
  codeContentRepoTitle: 'Run code from any public or private repositories',
  codeContentRepoSubTitle: '80% of code was already written. Find what you’re looking for and use it. Launch any code from GIT with Corezoid in just a couple of clicks.',
  codeContentProcTitle: 'Repository code as a part of a process',
  codeContentProcSubTitle: 'Does your process lack functionality? Just add a GIT Call node and you can use repository code as part of your process.',
  codeContentLangTitle: 'Any programming language',
  codeContentLangSubTitle: 'Corezoid supports all popular programming languages, such as JS, Java, Go, Python, C++, C# ...',
  codeContentHostTitle: 'Hosting without hosting',
  codeContentHostSubTitle: 'You found the necessary code on GIT, but how do you put it to good use? With Corezoid you just have to specify the repository address and build commands.',
  codeContentScaleTitle: 'Native horizontal scaling',
  codeContentScaleSubTitle: 'Scaling shouldn’t be a hassle - Corezoid takes care of it for you. When the load increases, we automatically increase the server capacity and allow it to scale up to the maximum allowed by your pricing plan.',

  // iot
  iotHeaderTitle: 'Control the “IoT jungle”',
  iotHeaderSubTitle: 'The world is filled with hundreds of thousands of devices from myriad of vendors, each having their own protocol. Corezoid helps merge this “hardware zoo” into a unified ecosystem and enables you to manage it effectively.',
  iotContentIronParkTitle: 'Unify the “hardware zoo” in a single ecosystem',
  iotContentIronParkSubTitle: 'The number of IoT devices rises every day and every IoT manufacturer has their own protocol. Corezoid creates a unified protocol and a bus type of network for device interaction and unites them all in one ecosystem.',
  iotContentEventTitle: 'Control events in real-time (Event Stream Processing)',
  iotContentEventSubTitle: 'Corezoid helps control the stream of events from your devices in real time with flexible logic of aggregation, routing and event handling.',
  iotContentEscalTitle: 'Set up automatic escalations',
  iotContentEscalSubTitle: 'Want to be notified of activity results or abnormal events? Corezoid allows you to set up monitoring, processing and notifications of unforeseen situations: surges in load, device failures, failures of external and internal services, as well as any other abnormal cases.',
  iotContentOnlineTitle: 'Real-time dashboards',
  iotContentOnlineSubTitle: 'Corezoid allows you to automatically track any metrics from your IoT devices and displays statistics in real time using built-in dashboards.',
  iotContentPerformanceTitle: 'Performance',
  iotContentPerformanceSubTitle: 'Thanks to high fault tolerance and automatic horizontal scaling, Corezoid allows you to easily handle hundreds of thousands of events per second.',

  // communication
  communicationHeaderTitle: 'Control and automate communications',
  communicationHeaderSubTitle: 'Customers want to communicate with businesses via dozens of channels. Corezoid helps you manage all these channels and automates your communications with bots.',
  communicationContentChanelTitle: 'Connect with any communication channels',
  communicationContentChanelSubTitle: 'Corezoid allows you to work with any communication channels and data sources. As long as they have an API.',
  communicationContentRobotTitle: 'Automate communication with bots',
  communicationContentRobotSubTitle: 'Increase efficiency of communication and reduce costs. Corezoid helps create bots that automate communication. Our best customers automate up to 80% of their contact center calls.',
  communicationContentBisLogicTitle: 'One business logic for all communication channels',
  communicationContentBisLogicSubTitle: 'You no longer need to create algorithms for each communication channel separately. With the help of Corezoid, you can create universal algorithms that work with any communication channel.',
  communicationContentBotTitle: 'Communications Orchestrator',
  communicationContentBotSubTitle: 'Want to start communicating with your clients via instant messengers? Thanks to Corezoid and a set of ready-made processes called “Communications Orchestrator”, you can connect to all popular instant messengers with just a few clicks and in a matter of hours create your first bots that will help your customers.',

  // events
  eventsHeaderTitle: 'Keep everything under control!',
  eventsHeaderSubTitle: 'Lack Lack of control is one of the key problems facing companies. Control.events helps you effectively monitor and manage events that occur both inside and outside your company. Control any type of events: projects and tasks within the company, meetings on calendars, contract stages with partners and contractors, and much more. Thanks to Corezoid, you can integrate Control.events with an unlimited number of event sources.',
  eventsContentCheckTitle: 'Keep everything under control',
  eventsContentCheckSubTitle: 'Connect events from any sources and manage all company events in a single interface.',
  eventsContentDashTitle: 'Event graph',
  eventsContentDashSubTitle: 'Control.events helps you to visualize connections and set up rules for interaction between different events, making them manageable.',
  eventsContentWorkTitle: 'Teamwork',
  eventsContentWorkSubTitle: 'Work on joint projects and tasks with colleagues, connect people outside the company and communicate using commentaries.',
  eventsContentImportTitle: 'Import events from any source',
  eventsContentImportSubTitle: 'Import events using ready-made connectors from all popular sources: Gmail, Google Drive, Calendar, JIRA, Slack, Zoom or connect any other event sources using Corezoid.',
  eventsContentControlTitle: 'Keep everything at hand',
  eventsContentControlSubTitle: 'Control.events works in browsers and all popular instant messengers.',

  // knowledge
  knowledgeHeaderTitle: 'Manage company information and knowledge',
  knowledgeHeaderSubTitle: 'Company information and knowledge are hidden in data and stored in dozens of separate softwares with different formats. Cloud expert system DeepMemo will help to connect data from different sources into a single repository, extract information and knowledge from raw data to make better and more effective decisions: from super personalization to anti-fraud.',
  knowledgeContentBaseTitle: 'United client base',
  knowledgeContentBaseSubTitle: 'You struggle to collect all the necessary information about customers, transactions, and communication because it is stored in dozens of different software systems and databases? DeepMemo will help to store and manage data with a single system.',
  knowledgeContentBillingTitle: 'Billing',
  knowledgeContentBillingSubTitle: 'Collect information on resources usage and create expert systems for processing and managing data with a single tool.',
  knowledgeContentEscalTitle: 'Automatic extraction of knowledge and connections',
  knowledgeContentEscalSubTitle: 'The accumulation of raw data without benefit is a useless exercise. DeepMemo will help you to set up algorithms for the automatic extraction of knowledge and hidden connections from the data stream, and the resulting insights will allow you to make better decisions on managing your business.',
  knowledgeContentAntifraudTitle: 'Antifraud',
  knowledgeContentAntifraudSubTitle: 'With built-in group and individual limits, system of scales and counters, DeepMemo will help you create expert systems for managing risky operations that make decisions in real time.',
  knowledgeContentSuperTitle: 'Super Personalization',
  knowledgeContentSuperSubTitle: 'With the help of expert systems, DeepMemo can find hidden connections and knowledge about customers that allows you to create unique, super personalized offers to increase your sales.',

  // people
  peopleHeaderTitle: 'Manage leads, customers, employees, partners, suppliers...',
  peopleHeaderSubTitle: 'Transfer your people management technology to Corezoid: create sales funnels, manage customer life cycles, suppliers and inventory; track fulfillment and non-fulfillment of KPIs, as well as employee tasks.',
  peopleContentLifeTitle: 'Automate customer lifecycles',
  peopleContentLifeSubTitle: 'Corezoid helps segment the customer lifecycle into all possible states from onboarding to sales and support. It allows you to adjust your response to changes or absence of changes in a user\'s state. Create and run processes that automatically guide your customers through the lifecycle. All you have to do is control the metrics and optimize the processes.',
  peopleContentDeshTitle: 'Control KPIs using online dashboards',
  peopleContentDeshSubTitle: 'Control your business with Corezoid\'s built-in online dashboards. They allow you to monitor key metrics and make management decisions in real time.[br][br]' +
    'Create new and modify existing dashboards with just a few clicks without developers.',
  peopleContentPulseTitle: 'Stay on top of your business with escalations',
  peopleContentPulseSubTitle: 'An employee did not complete a task on time? Is a contractor overdue? A partner did not process an application? Corezoid helps you set up different levels of escalation and receive notifications about important events in your business.',
  peopleContentControlTitle: 'Manage contractors, suppliers, partners in a single interface',
  peopleContentControlSubTitle: 'Corezoid allows you to combine and unify the communication protocols of different contractors, suppliers and partners to manage all of your processes within a single interface.',

  // cloud
  cloudHeaderTitle: 'Serverless computing',
  cloudHeaderSubTitle: '“Banking is essential, banks are not” (c) Bill Gates[br][br]' +
    'Admit it, you need the results of computing, not the process. Create and run algorithms of any complexity on Corezoid cloud that is secure and autoscales.',
  cloudContentHostTitle: 'Hosting without hosting',
  cloudContentHostSubTitle: 'You no longer need to choose the appropriate hosting for each application or programming language. Simply sign up on Corezoid, after which we will automatically deploy the environment for you, where you can create and host processes of any complexity in any programming language with just a few clicks.',
  cloudContentDeployTitle: 'Deploy in 1 click',
  cloudContentDeploySubTitle: 'With Corezoid, you no longer need to bother with the tools of continuous integration (CI). Deploy and apply changes to real-time processes with just 1 click.',
  cloudContentVersTitle: 'Versioning',
  cloudContentVersSubTitle: 'Create new and modify existing ones or roll back to a previous version of processes - all versioning functions available in GIT are available in Corezoid.',
  cloudContentScaleTitle: 'Horizontal scaling',
  cloudContentScaleSubTitle: 'Focus on business, not scaling issues. Corezoid provides native automatic horizontal scaling, that allows you to save on devops and developers that could be solving such problems.',
  cloudContentPerformanceTitle: 'Performance',
  cloudContentPerformanceSubTitle: 'Corezoid allows you to create services that easily perform a load of hundreds of thousands of requests per second. Corezoid is a reliable solution for high-load systems that has been proved by leading global companies.',
  cloudContentSecurityTitle: 'Security',
  cloudContentSecuritySubTitle: 'Corezoid was born in the banking industry, where security is crucial. Corezoid successfully passed a number of audits from companies such as WU, METRO, AWS and complies with PCI DSS and ISO27001 requirements. Corezoid is also a recommended solution for the financial industry under the AWS Financial Services Competency program.',

  // resource
  resourceHeaderTitle: 'Ресурсы',
  resourceHeaderButtonBlogTitle: 'News',
  resourceHeaderButtonCaseTitle: 'Кейсы',
  resourceBlogTitle: 'News',
  resourceBlogButtonText: 'Перейти в блог',
  resourceBlogMore: 'Read full article',
  resourceCaseTitle: 'Case Studies',
  resourceCaseSubTitle: 'Learn how companies use Corezoid Actor Engine to manage their business, lower their spendings, increase  their time-to-market speed and achieve outstanding results.',
  resourceCaseButtonText: 'Read full article',
  resourceCaseItem1Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem1More: 'Read full article',
  resourceCaseItem2Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem2More: 'Read full article',
  resourceCaseItem3Title: 'Integration of Amo CRM, Sunflower IP Telephony, website, and payment gateway for VUSO company',
  resourceCaseItem3More: 'Read full article',
  resourceCaseShowMore: 'More',

  // media
  mediaTitle: 'Media',
  mediaSubTitle: 'News, articles and other materials related to Corezoid Inc.',
  mediaShowMore: 'Load more',
  mediaItem1Title: 'Visa убивает банки',
  mediaItem1More: 'Читать полностью',

  // slider
  sliderTitle: 'How Corezoid helps our clients',
  sliderResult: 'Result',
  sliderTCB: 'Using Corezoid, TBC Bank launched a new “challenger bank” called “Space Bank”. It’s a mobile only bank, with zero physical branches. The objective of Space bank is to provide the best digital experience possible at an affordable price. It took 8 months and a team of 20 people to launch Space bank from scratch.',
  sliderAvon: 'Avon laid the foundation for a company’s “digital core”. Avon business analysts are now able to assemble and fine-tune business processes, without the pain of waiting in a queue for IT-development. Employees of IT-department got the opportunity to focus on development of APIs needed for business people. Following this strategy, Avon will be able to experiment fast with new ideas, and constantly improve the quality of client service as well as company’s business indicators.',
  sliderMetro: 'Based on Corezoid, METRO developed a mobile application and a chat-bot in the Viber messenger. The bot provides the issuance of METRO membership cards just in 1 click, instead of filling in questionnaires and getting plastic cards. Also, thanks to Corezoid, METRO can communicate with customers using personalized trigger-based notifications.',
  sliderMetroName: 'Marina Voloshina',
  sliderMetroStatus: 'Head of CRM at METRO',
  sliderPumb: 'Corezoid became a huge catalyst and facilitator of digital transformation at FUIB. Corezoid allowed us to significantly widen the bottleneck of our IT resources. When there\'s a "digital core" in the form of a process engine, which is independently managed by the business itself, the organizational changes occur an order of magnitude faster. The business begins to freely organize its processes, thinking in MVP categories, building and improving prototypes.',
  sliderPumbName: 'Andrey Begunov',
  sliderPumbStatus: 'CTO at PUMB',
  sliderSpaceBank: 'One of our main tasks was to increase the speed of development and launch of new products. At the same time, we had to integrate with the old legacy core, responsible for regulatory requirements, compliance issues that worked as our main general ledger. We use Corezoid technology as our main system to conduct almost the whole development process. Our main products, processes, and the server part of the mobile application are built on the Corezoid platform.',
  sliderSpaceBankName: 'Lasha Gurgenidze',
  sliderSpaceBankStatus: 'Product Owner at Space Neobank',
  sliderMambu: 'Corezoid attracted my attention with its fundamental approach to developing the technology. I realized that Corezoid wasn’t just another BPM tool. In fact, Corezoid was based on fundamental principles of mathematics: finite automata theory and “state machine” concepts.',
  sliderMambu2: 'Corezoid has this unique offering that it lets people to easily work with APIs. There is no need to write java code, think of any special architecture, deployment rules, testing approaches. All these things are a part of the Corezoid solution. All you need is an understanding of the process you want to build. And then just go and build it.',
  sliderMambuName: 'Ben Goldin',
  sliderMambuStatus: 'CTO at MAMBU',
  sliderTas: 'In terms of technology, I confess, we tried to look for alternatives to Corezoid but realized that there are no alternatives to it at the moment. We build processes in Corezoid whenever we feel that we need dynamics, whenever there is a desire to change something almost every day, to experiment on the fly, to run several different options of a business process with a final assessment of efficiency.',
  sliderTasName: 'Alexander Nikolenko',
  sliderTasStatus: 'Deputy Chairman of the Board',
  sliderLanit: 'The success is dependent of a number of factors, including the speed of launching a new product, versatility of tools for solving various tasks, and flexibility of setting up processes. The emergence of such high-tech products as Corezoid catalyzes further execution of transformation processes in companies. Changes in the organization occur much faster when there is a digital core, a process system, controlled by the business itself.',
  sliderLanitName: 'Igor Pototsky',
  sliderLanitStatus: 'CEO of LANIT BPM',
  sliderButton: 'Read full case study',
  sliderGlovo: 'I knew about Corezoid. And I knew that there is a Middleware company that approaches things in a smart way and offers scalable solutions. Now Middleware allows Glovo to integrate new partners much faster.',
  sliderGlovoName: 'Dmitry Rasnovsky',
  sliderGlovoStatus: 'GM Glovo Ukraine',
  sliderBlueFinance: 'We find Corezoid processing power a game-changer on this topic. We have shifted focus from firefighting to improving our automation processes now built on competitive advantages instead. Every two weeks we accomplish releases that would have otherwise needed months of developments and bug-solving. By now we are working toward a fully automated loan processing flow that would have not been reachable only a few months back.',
  sliderBlueFinanceName: 'Julio Gómez',
  sliderBlueFinanceStatus: 'CEO Blue Finance Ibérica',

  // pricing
  pricingCorezoidCloud: 'Corezoid Cloud',
  pricingSupport: 'Support',

  pricingCloudTitle: 'Get the right plan for your business',
  pricingCloudSubTitle: 'Always know what you’ll pay.',
  pricingCloudState: 'State changes/month',
  pricingCloudRequests: 'Requests/sec',
  pricingCloudPriceMonth: '/month',
  pricingCloudButtonText: 'Continue',
  pricingCloudBilled: 'Billed:',
  pricingCloudMonthly: 'monthly',
  pricingCloudYearly: 'yearly',
  pricingCloudPromoTrialTitle: 'Free',
  pricingCloudPromoTrialText: 'Get your trial for 30-days or 1M state changes for free and explore Corezoid benefits.',
  pricingCloudPromoDeployTitle: 'Commercial License',
  pricingCloudPromoDeployText: 'Want to deploy Corezoid on your own environment? Contact us to discuss it!',
  pricingCloudType1: 'Cloud',
  pricingCloudType2: 'In-House',
  pricingPayType: 'Payment method',
  pricingPayCard: 'Payment card',
  pricingPayInvoice: 'Invoice',
  pricingPaySummary: 'Summary',
  pricingPaySummaryProduct: 'Product',
  pricingPaySummaryHosting: 'Hosting',
  pricingPaySummaryPayment: 'Payment card',
  pricingPaySummaryLicense: 'License',
  pricingPaySummaryRPS: 'RPS',
  pricingPaySummaryStorage: 'Storage',
  pricingPaySummaryDuration: 'Duration',
  pricingPaySummaryStartingAt: 'Starting at',
  pricingPaySummaryState: 'State changes / month',
  pricingPaySummaryTotal: 'Total',
  pricingPaySummaryBuy: 'Buy Corezoid',
  pricingPayBillingInfo: 'Billing information',

  pricingQACardTitle: 'WHAT IS THE DIFFERENCE BETWEEN PAID CLOUD AND IN-HOUSE LICENSES?',
  pricingQACardCloudTitle: 'Paid Cloud license',
  pricingQACardCloudDescription1: 'Provides State Changes and RPS as specified in your pricing plan',
  pricingQACardCloudDescription2: 'Is used on the Simulator.Company domain',
  pricingQACardCloudDescription3: 'Doesn’t have an expiration date (you can pay for it monthly or yearly)',

  pricingQACardInHouseTitle: 'In-House paid license',
  pricingQACardInHouseDescription1: 'Provides RPS as specified in your pricing plan with State Changes not being priced or limited',
  pricingQACardInHouseDescription2: 'Is used in a separate environment',
  pricingQACardInHouseDescription3: 'Has an expiration date',

  pricingQACardFooterTitle: 'Note',
  pricingQACardFooterDescription: 'You can purchase and use multiple active In-House paid licenses.',


  // caab
  caabHeaderTitle: 'Corezoid as a Back-end (CaaB)',
  caabHeaderSubTitle: 'Бессерверное управление вашим сайтом или мобильным приложением ',
  caabButton: 'ПОПРОБОВАТЬ CaaB',
  caabCloudTitle: 'Бессерверная архитектура',
  caabCloudText: 'Создавайте и управляйте бэк-ендом веб и мобильных приложений без серверов, разработчиков и DevOps.',
  caabApiTitle: 'Процессы как API',
  caabApiText: 'Corezoid позволяет создавать процессы, которые являются API для ваших приложений.',
  caabEventsTitle: 'Обработка событий',
  caabEventsText: 'Настройте обработку всех событий ваших веб и мобильных приложений в режиме реального времени.',
  caabDashbordsTitle: 'Онлайн дашборды',
  caabDashbordsText: 'Встроенные дашборды позволяют держать руку на пульсе вашего бизнеса в режиме реального времени.',
  caabEscalateTitle: 'Онлайн эскалации',
  caabEscalateText: 'Настраивайте уведомления при наступлении целевых событий или сбоев в режиме реального времени. ',
  caabDeployTitle: 'Деплой в 1 клик',
  caabDeployText: 'Все изменения процессов ваших приложений моментально деплоятся в 1 клик.',

  // aboutUs
  aboutUsTitle: 'Mission',
  aboutUsSubTitle: '[b]Our mission is to make every company in the world a technologically agile and train it to interact with other companies[/b][br][br]Corezoid Inc is a tech company that creates an operating system and application software for business digitalization. Companies of any size - from startups to Fortune 500 leaders are benefitting from our products in business management.',
  aboutUsFormTitle: 'Contact us',
  aboutUsCompanyTitle: 'The world\'s largest companies trust us',
  aboutUsCompanySubTitle: 'Customers from 120 countries around the world use Corezoid to grow their business.',
  aboutUsEmployeesTitle: 'Team',
  aboutUsEmployeesSubTitle: 'Corezoid headquartered at Redwood (US) with R&D office at Ukraine.',
  aboutUsEmployeesInfoYearTitle: '2014',
  aboutUsEmployeesInfoYearText: 'founded',
  aboutUsEmployeesInfoEmployeesTitle: '80+',
  aboutUsEmployeesInfoEmployeesText: 'employees',
  aboutUsTechnologyTitle: 'Technologies',
  aboutUsTechnologySubTitle: 'Our technology stack to manage your business',

  // innovation
  historyInnovationTitle: 'Innovation History',
  historyInnovationSubTitle: 'Innovations developed by Alexander Vityaz',

  // partnership
  partnershipTitle: 'Partners',
  partnershipSubTitle: 'Use Corezoid Actor Engine to create innovative digital products for your clients.',
  partnershipPlusesTitle: 'Why Corezoid?',
  partnershipPlusesEcoTitle: 'United ecosystem for managing all company processes',
  partnershipPlusesEcoSubTitle: 'Do you have to look for niche products and hire specialists to solve client tasks? Corezoid is a universal instrument that allows to solve practically any kind of task using only one kind of specialist.',
  partnershipPlusesProtoTitle: 'Rapid prototyping and making changes',
  partnershipPlusesProtoSubTitle: 'Does your client want to see a prototype as soon as possible? No problem. Corezoid allows for prototyping products without developers’ help within hours and making changes according to client requirements is just as fast.',
  partnershipPlusesTimeTitle: 'Lower time-to-market down to 0',
  partnershipPlusesTimeSubTitle: 'You can advance at the same pace as the market or even faster. Forget about development queues and IT-department dependence. Thanks to low entry threshold any manager can learn how to use Corezoid and create complete business-products within days or weeks, not months or years.',
  partnershipPlusesHostTitle: 'Hosting without hosting',
  partnershipPlusesHostSubTitle: 'Create products in Corezoid cloud that supports automatic horizontal scaling and you won’t have to employ expensive devops for each cloud platform.',
  partnershipPartnersTitle: 'List of certified partners',
  partnershipPartnersUkraine: 'Country: Ukraine',
  partnershipPartnersEurope: 'Region: Europe',
  partnershipPartnersWorldwide: 'Region: Worldwide',
  partnershipPartnersRussia: 'Country: Russia',
  partnershipPartnersEuropeCIS: 'Region: Europe and CIS',
  partnershipPartnersCompetenciesTitle: 'Competencies:',
  partnershipPartnersButton: 'Go to partner site',
  partnershipCertificationTitle: 'Want to become a Corezoid certified partner?',
  partnershipCertificationSubTitle: 'Certified Corezoid partners receive bonuses for attracting clients and have access to free master classes meant for employee training, lead stream from Corezoid website and a list of available certified specialists.',
  partnershipCertificationButton: 'Become a Corezoid certified partner right now!',
  partnershipVacancyTitle: 'Employ a Professional Corezoid Developer',
  partnershipVacancySubTitle: 'Certified specialists will be able to see your proposal and submit their candidacy.[br][ol][li]You post a vacancy[br][/li][li]We send the vacancy to all certified Corezoid specialists[br][/li][li]We give you CVs of interested professionals[br][/li][li]You continue to communicate with these specialists for the future recruitment[/li][/ol]',
  partnershipVacancyButton: 'Submit vacancy',

  // jobs
  jobsTitle: 'Vacancies',
  jobsSubTitle: 'We always seek the best talent to join our team. If you want to become part of a company that changes the world, find a vacancy that matches your capabilities and apply.',
  jobsMwTitle: 'Why Corezoid Inc?',
  jobsMwAmbitiousTitle: 'We are one of the most ambitious and innovative tech companies in the world.',
  jobsMwAmbitiousSubTitle: 'We are working with global clients - from startups to the largest international companies. Our technology ecosystem assists in managing business, innovating, reducing expenses and launching products 100x faster.',
  jobsMwInterestingTitle: 'Difficult, but interesting.',
  jobsMwInterestingSubTitle: 'We are used to working at the coalface between research, learning new technologies and scoring high business KPIs. Not everybody will manage the difficulties we face everyday, but that will help you to grow exponentially with us.',
  jobsListTitle: 'List of Corezoid Inc vacancies.',
  jobsListSubTitle: 'We always seek the best talent to join our team. If you want to become part of a company that changes the world, find a vacancy that matches your capabilities and apply.',
  jobsListLastItem: 'Didn’t find a vacancy?[br]We need proactive people![br][br]To send CV',
  jobsListButton: 'Apply',
  jobsListButtonInfo: 'Details',
  jobsPartnerListTitle: 'Vacancies for certified Corezoid specialists from clients and partners',
  jobsPartnerListSubTitle: 'Are you a certified Corezoid specialist or want to become one? With Corezoid skills you will always be in demand.',
  jobsPartnerListButton: 'Apply',
  jobsCoursesTitle: 'Do you want to become a certified Corezoid specialist?',
  jobsCoursesSubTitle: 'Take a training course and prove your Corezoid skills by passing certification.',
  jobsCoursesButton1: 'Learn Corezoid',
  jobsCoursesButton2: 'About certification',

  // learn
  learnTitle: 'Learn Corezoid',
  learnSubTitle: 'Do you want to learn Corezoid but don’t know how to begin? We suggest various training options for different client types and specialist grades: starting with free education materials up to individual corporate training. Choose an option you like and a training schedule and get going to success together with Corezoid!',
  learnOrderWorkshopButton: 'Buy workshop',
  learnContentDescription: 'Media in the sections below will help you quickly start using Corezoid regardless of your skill level, as well as solve specific problems by using existing examples.',
  learnVideoTitle: 'Corezoid.com quick start',
  learnVideoPart1Title: 'Workspace, Condition node overview',
  learnVideoPart2Title: 'API Call and Set Parameter nodes overview',
  learnVideoPart3Title: 'Call Process and Reply to Process nodes overview',
  learnVideoPart4Title: 'Overview of Copy Task, Modify Task, and Waiting for Callback nodes',
  learnVideoPart5Title: 'State Diagram overview',
  learnVideoPart1List1: 'In this video, we overview the workspace and show how to create your first process.',
  learnVideoPart1List2: 'Each Corezoid process consists of various types of nodes.',
  learnVideoPart1List3: 'Each node type implements its own data processing logic.',
  learnVideoPart1List4: 'This video also introduces the Condition node that allows you to:',
  learnVideoPart1List4Item1: 'set the conditions for processing and moving a task in a process.',
  learnVideoPart2List1: 'In this video, we show how to work with the Set Parameter node, which allows you to:',
  learnVideoPart2List1Item1: 'add new parameters and values to an existing task;',
  learnVideoPart2List1Item2: 'modify parameter values of an existing task.',
  learnVideoPart2List2: 'The video also contains an overview of the API Call node, which allows you to:',
  learnVideoPart2List2Item1: 'call external APIs.',
  learnVideoPart3List1: 'In this video, you will learn how to use the Call Process node. This node allows you to:',
  learnVideoPart3List1Item1: 'call another Corezoid process from your process.',
  learnVideoPart3List2: 'The video also shows you how to use the Reply to Process node that allows you to:',
  learnVideoPart3List2Item1: 'send a reply to another process.',
  learnVideoPart4List1: 'This video contains an overview of the Copy Task node, which allow you to:',
  learnVideoPart4List1Item1: 'copy an entire task or its part to another process',
  learnVideoPart4List2: 'and of the Modify Task node that allows you to:',
  learnVideoPart4List2Item1: 'modify a task in another process.',
  learnVideoPart4List3: 'The video shows you how to work with the Waiting for Сallback node, which allows you to:',
  learnVideoPart4List3Item1: 'keep the task in the node until it receives a response from an external system.',
  learnVideoPart5List1: 'This video shows how to work with State Diagrams, which are optimized for storing tasks and allow you to:',
  learnVideoPart5List1Item1: 'manage client states.',
  learnVideoPart5List2: 'The video also demonstrates the use of the Copy Task node in a State Diagram.',
  learnContentStartTitle: 'Quickstart',
  learnContentStartSubTitle: 'Step-by-step guide to start using Corezoid',
  learnContentStartText: 'Have you just started to use Corezoid? We made a step-by-step guide to help you quickly create your first process and to show that learning Corezoid is easy.',
  learnContentStartButton: 'Get Started',
  learnContentStartButtonLink: 'https://doc.corezoid.com/docs/quick-start',
  learnContentTutorialTitle: 'Tutorials',
  learnContentTutorialSubTitle: 'Free training online-materials and projects',
  learnContentTutorialText: 'Learn and improve your skills with free materials and projects for both starters and experienced Corezoid users.',
  learnContentTutorialButton: 'View tutorials',
  learnContentTutorialButtonLink: 'https://doc.corezoid.com/docs/google-oauth',
  learnContentDocTitle: 'Documentation',
  learnContentDocSubTitle: 'Includes all entities, functions and API manual',
  learnContentDocText: 'Main user guide that contains description of everything that is necessary to start and advance using Corezoid.',
  learnContentDocButton: 'Read documentation',
  learnContentDocButtonLink: 'https://doc.corezoid.com/docs',
  learnContentWorkShopTitle: 'Workshops',
  learnContentWorkShopSubTitle: 'Individual Corezoid trainings for groups and corporate clients',
  learnContentWorkShopText: 'This is the fastest method to dive into Corezoid. We create individual education programs based on your needs, as a result you receive not only a well educated team, but also a prototype of a real business process.',
  learnContentWorkShopButton: 'Order a workshop',
  learnContentCertificationTitle: 'Certification',
  learnContentCertificationSubTitle: 'Prove your Corezoid skills and begin a new stage of your career',
  learnContentCertificationText: 'The demand for certified Corezoid specialists grows every day. Prove your qualification and we will help you get a job at our partners and clients.',
  learnContentCertificationButton: 'Pass certification',
  learnContentCommunityTitle: 'Community',
  learnContentCommunitySubTitle: 'Join the Corezoid Community',
  learnContentCommunityText: 'We have created a Corezoid Community - the place where you can share experiences, find answers, discuss questions and ideas.',
  learnContentCCommunityButton: 'Join',
  learnInformation: 'Recommendation: 96% of corporate clients start learning Corezoid with an individual workshop.',

  // tutorials
  tutorialsTitle: 'Tutorials',
  tutorialsSubTitle: 'Free tutorials and dozens of educational projects with step by step guidance will help you thoroughly and quickly sort out how Corezoid can be used to create innovative projects and to solve business tasks.',
  tutorialsButton: 'Request workshop',
  tutorialsContentTitle: 'Projects',
  tutorialsContentOAuthText: 'You will learn how to connect to Google APIs using Google OAuth 2.0.',
  tutorialsContentGmailText: 'You will learn how to use Gmail API to get unread emails and their details from the mailbox.',
  tutorialsContentSheetsText: 'You will learn how to add entries to a Google Sheets table using the Google AppScripts API call from Corezoid.',
  tutorialsContentFirebaseText: 'This tutorial will help you to learn how to work with cloud database called Firebase via API.',
  tutorialsContentMessagebirdText: 'You will learn how to set up a process with calling a MessageBird API to send SMS and then check the delivery status of your SMS.',
  tutorialsContentButtonMessagebirdLink: 'https://doc.corezoid.com/docs/messagebird',
  tutorialsContentEsputnikText: 'You will learn how to send emails via eSputnik API and check the delivery status of your emails.',
  tutorialsContentButtonEsputnikLink: 'https://doc.corezoid.com/docs/esputnik',
  tutorialsContentQueueTitle: 'Tasks Queue',
  tutorialsContentQueueText: 'You will get to know how to set up and process queues in Corezoid.',
  tutorialsContentButtonQueueLink: 'https://doc.corezoid.com/docs/creating-and-managing-a-request-queue',
  tutorialsContentButton: 'Get started',
  tutorialsContentButtonOAuthLink: 'https://doc.corezoid.com/docs/google-oauth',
  tutorialsContentButtonGmailLink: 'https://doc.corezoid.com/docs/google-gmail',
  tutorialsContentButtonGoogleSheetsLink: 'https://doc.corezoid.com/docs/google-appscripts',
  tutorialsContentButtonFirebaseLink: 'https://doc.corezoid.com/docs/firebase',
  tutorialsContentButtonBotPlatformLink: 'https://doc.corezoid.com/docs/bot-platform-20',
  tutorialsContentBotText: 'Create your first bot and connect it to the most popular messengers.',
  tutorialsLevel: 'Level',
  tutorialsNewbie: 'Newbie',
  tutorialsAdvanced: 'Advanced',
  tutorials1hour: '1 hour',
  tutorials3hours: '3 hours',
  tutorials5hours: '5 hours',
  tutorialsDuration: 'Duration',

  // workshops
  workshopsTitle: 'Workshop',
  workshopsSubTitle: 'This is the fastest method to dive into Corezoid. We create individual education programs based on your needs, as a result you receive not only a well educated team, but also a prototype of a real business process.',
  workshopsButton1: 'Buy workshop',
  workshopsButton2: 'Contact us',
  workshopsInfoDay: '3',
  workshopsInfoDayText: 'training days',
  workshopsInfoBusiness: '100%',
  workshopsInfoBusinessText: 'dive into the client\'s business case',
  workshopsInfoProto: '1',
  workshopsInfoProtoText: 'real business case',
  workshopsInfoParticipants: '1-6',
  workshopsInfoParticipantsText: 'participants',
  workshopsProgramText: 'Education program',
  workshopsProgramListNode: 'Learning all nodes and programming patterns in Corezoid',
  workshopsProgramListService: 'Learning instruments and service integrations matching your business case implementation',
  workshopsProgramListCases: 'Creating your business case',
  workshopsFormatTitle: 'Education format',
  workshopsFormatOnlineTitle: 'Online workshop',
  workshopsFormatOnlineText: 'We set up a group call in Zoom / Skype or any other convenient video communication service and conduct a 3-day remote education course.',
  workshopsFormatOnlinePrice: 'Workshop cost: 1,500$',
  workshopsFormatOnlineButton: 'Buy workshop',
  workshopsFormatOfflineTitle: 'Offline workshop',
  workshopsFormatOfflineText: '[b]There are 2 offline workshop options:[/b][br][ol][li]You come to our office for full immersion in Corezoid environment and disconnection from the daily grind. [br][/li][li]We send our specialists to your location.*[/li][/ol]',
  workshopsFormatOfflinePrice: 'Workshop cost: 1,500$',
  workshopsFormatOfflineComment: '* Travel expenses are not included in this cost',
  workshopsFormatOfflineButton: 'Buy workshop',
  workshopsLicenseTitle: 'Become an official Corezoid Education Center or Corezoid instructor',
  workshopsLicenseCenterTitle: 'Corezoid Education Center',
  workshopsLicenseCenterText: 'As an official education partner you receive a license to hold training seminars, certifications or Corezoid courses.',
  workshopsLicenseCenterButton: 'Apply',
  workshopsLicenseInstructorTitle: 'Corezoid Instructor',
  workshopsLicenseInstructorText: 'Pass a certification and join our certified instructors’ community to begin teaching people and companies how to use Corezoid.',
  workshopsLicenseInstructorButton: 'Apply',

  // certification
  certificationTitle: 'Certification',
  certificationSubTitle: 'The demand for certified Corezoid specialists grows every day. Prove your qualification and you will receive access to a community of certified specialists and Corezoid partners that can help you find new interesting projects or even a job.',
  certificationCertTitle: 'Corezoid Certification',
  certificationCertSubTitle: 'Corezoid specialist certificate shows employers that you are skilled to solve key challenges.',
  certificationCertJuniorTitle: 'Junior',
  certificationCertJuniorSubTitle: 'You receive Junior grade after studying learning materials and implementing 3 projects of “Newbie” level from the “Tutorials” section.',
  certificationCertJuniorButton: 'Receive Junior grade',
  certificationCertJuniorButtonLink: '/certification/junior/',
  certificationCertMiddleTitle: 'Middle',
  certificationCertMiddleSubTitle: 'To receive Middle grade you will need to study Corezoid documentation deeply, be able to work with both external and internal APIs; possess understanding of main Corezoid development patterns and implementations of at least 10 projects.',
  certificationCertMiddleButton: 'Pass certification',
  certificationCertSeniorTitle: 'Senior',
  certificationCertSeniorSubTitle: 'Senior grade is eligible to specialists with >1 year Corezoid experience and have successfully created more than 25 projects of various difficulty levels. Senior specialist is expected to understand how to work with all Corezoid data types, including XML, SOAP; have experience of automatic process generation using Corezoid API; know how to juggle Corezoid best practices for optimal business-task solutions.',
  certificationCertSeniorButton: 'Pass certification',
  certificationCorpTitle: 'Corporate certification',
  certificationCorpSubTitle: 'Group corporate certification assists in organizing Corezoid working teams to effectively solve existing tasks and in creating a training program for your employees based on independent skill and knowledge assessment.',
  certificationCorpButtons: 'Order corporate certification',
  certificationPartnerTitle: 'Corezoid partner certification',
  certificationPartnerSubTitle: 'Certified Corezoid partner, apart from the status, also receives access to a closed community of partners for experience and client exchange, exclusive training webinars for employees, lead stream from Corezoid.com and a list of certified specialists that are looking for jobs.',
  certificationPartnerButtons: 'Pass certification',

  // support
  supportTitle: 'Support',
  supportSubTitle: 'Choose your pricing plan',
  supportSalesTest1: 'Prices are given per company, excluding VAT, repatriation tax and travel expenses.',
  supportSalesTest2: 'Required packages for “Private Cloud” and “On-premises” hosting types start from “Business”.',
  supportSalesQuestion: 'Do you want to buy Corezoid and have questions about support? Contact us directly!',
  supportSalesButtonText: 'Contact sales',

  supportListRowTitle: '',
  supportListRowService: 'Client support and community',
  supportListRowHealth: 'System state and notifications',
  supportListRowTechSupport: 'Technical support',
  supportListRowTechChannels: 'Support channels',
  supportListRowTechSeverity: 'Problem severity and response time',
  supportListRowArchSupport: 'Architectural support',
  supportListRowEnvSupport: 'Environment support',
  supportListRowGuidance: 'Proactive tips',
  supportListRowLauSupport: 'Help at start',
  supportListRowTrainings: 'Education',
  supportListRowPrice: 'Price/month',

  supportListBaseTitle: 'Basic',
  supportListBaseService: '24/7 access to client services, documentation, white papers and support.',
  supportListBaseHealth: 'Access to Health Check Monitoring page',
  supportListBaseTechSupport: '9/5',
  supportListBaseTechChannels: 'Email',
  supportListBaseTechSeverity: '> 1 day',
  supportListBaseArchSupport: 'Not provided',
  supportListBaseEnvSupport: 'Not provided',
  supportListBaseGuidance: 'Not provided',
  supportListBaseLauSupport: 'Not provided',
  supportListBaseTrainings: 'Not provided',
  supportListBasePrice: 'Free',

  supportListBusinessTitle: 'Business',
  supportListBusinessService: '24/7 access to client services, documentation, white papers and support.',
  supportListBusinessHealth: 'Access to Health Check Monitoring page',
  supportListBusinessTechSupport: '9/5',
  supportListBusinessTechChannels: 'Email / chat',
  supportListBusinessTechSeverity: 'General recommendations: under 24 hours[br][br]System failure: under 12 hours[br][br]Production environment failure: under 6 hours[br][br]Production environment stopping: under 3 hours',
  supportListBusinessArchSupport: 'Recommendations based on analysis of your cases',
  supportListBusinessEnvSupport: 'Review, recommendations and reports',
  supportListBusinessGuidance: 'Not provided',
  supportListBusinessLauSupport: 'System setup manual',
  supportListBusinessTrainings: 'Not provided',
  supportListBusinessPrice: '$2,000',

  supportListInterpriseTitle: 'Enterprise',
  supportListInterpriseService: '24/7 access to client services, documentation, white papers and support.',
  supportListInterpriseHealth: 'Access to Health Check Monitoring page',
  supportListInterpriseTechSupport: '24/7',
  supportListInterpriseTechChannels: 'Email / chat / phone',
  supportListInterpriseTechSeverity: 'General recommendations: under 24 hours[br][br]System failure: under 12 hours[br][br]Production environment failure: under 6 hours[br][br]Production environment stopping: under 3 hours[br][br]Critical error that stops your business: under 15 minutes.',
  supportListInterpriseArchSupport: 'Personal consultations based on your product analysis, applications and architecture',
  supportListInterpriseEnvSupport: 'Review, recommendations and reports',
  supportListInterpriseGuidance: 'Technical manager is allocated',
  supportListInterpriseLauSupport: 'System setup manual and best practices',
  supportListInterpriseTrainings: '1 course per month',
  supportListInterprisePrice: 'TBD',

  // questions
  questionsTitle: 'F.A.Q.',
  questionsSubTitle: 'Frequently Asked Questions from our users',
  questionsCalcTitle: 'What is a State Change and how to calculate them?',
  questionsCalcText: 'A State Change takes place when a task moves from one node to another. A task passing through four nodes gets three State Changes. Total number of State Changes can be expressed as [b]SC = (N – 1) x T[/b], where:',
  questionsCalcTextUl1: '[b]SC[/b] is the number of State Changes',
  questionsCalcTextUl2: '[b]N[/b] is the number of nodes in a Process',
  questionsCalcTextUl3: '[b]T[/b] is the number of tasks received in a Process',
  questionsRequestTitle: 'What is RPS (Requests per second) and how to calculate them?',
  questionsRequestText: 'Requests per second (RPS) represent the number of tasks that can be sent to a Process within 1 second. For example, if you’ve purchased the 10 RPS license and try to send 11 tasks per 1 second to a Process, the last task won’t be sent to the Process, and you will receive the PRS limit reached error.',
  questionsAfterTitle: 'What happens if I use up all the State Changes of my license',
  questionsAfterText: 'After all State Changes of your license have been used up, your RPS equals 0 and you can’t send tasks to a Process until you replenish your State Changes account.',
  questionsSupportTitle: 'Is support included in the license?',
  questionsSupportText: 'Yes, all our clients receive a basic support package free of charge. Other support packages can be purchased individually. For more information, please go to the Support page.',
  questionsFreeTitle: 'Do you provide a free license?',
  questionsFreeText: 'Yes, you can have a free Cloud license with RPS=2 for an unlimited period. When you register for the first time, you are given 1 million State Changes. When you run out of them, you will not be able to send tasks to Processes and will have the RPS=0 limit.',
  questionsProvidedTitle: 'Do you give refunds?',
  questionsProvidedText: 'No, we do not have this option.',
  questionsChangeTitle: 'Can I change the RPS for my license?',
  questionsChangeText: 'Yes, you can. To do this, purchase a new license and specify the needed RPS.',

  // blog
  blogViewAll: 'VIEW ALL',
  blogRecommendedArticle: 'Related article:',

  // post
  postDemoTitle: 'Let\'s create your first process!',
  postDemoBtnCreate: 'Start Free Trial',
  postDemoBtnCreateLink: 'https://admin.corezoid.com/',
  postDemoBtnDemo: 'Request Demo',

  // faq
  faqTitle: 'Frequently Asked Questions (FAQ)',
  faqFindText: 'Find anything (like pricing, SLA, or reset password)',
  faqGeneral: 'general',
  faqTechnical: 'technical',
  faqDemoTitle: 'Let’s get started with Corezoid Actor Engine!',
  faqDemoBtnCreate: 'Create first process',
  faqDemoBtnCreateLink: 'https://admin.corezoid.com/',
  faqDemoBtnDemo: 'Request Demo',

  // download
  downloadTitle: 'Download Free[br] Trial Corezoid Instance',
  downloadSubTitle: 'Build, host and run your processes in a single interface without coding',
  downloadLinks: '[iurl=https://doc.corezoid.com/docs/quick-start/]Quick Start[/iurl][br][iurl=/tutorials/]Tutorials[/iurl][br][iurl=https://doc.corezoid.com/docs/]Documentation[/iurl]',
  downloadLinksTitle: 'Helpful links:',
  downloadLinksMWDocs: 'VMware Installation Guide',
  downloadLinksMWInstall: 'VMware Fusion Installation Guide',
  downloadLinkInstruction: 'Installation guide is included',
  downloadSelectTitle: 'Select instance type:',
  downloadVersion: 'Version',
  downloadSize: 'File size',
  downloadTerms: 'By clicking "Download" you agree to [br][iurl=/terms-and-conditions/]Terms of Service[/iurl] and [iurl=/terms-and-conditions/]Privacy Policy[/iurl]',
  downloadButtonText: 'Download',
  downloadButtonTextGet: 'Get',
  downloadButtonTextDisabled: 'Coming soon',
  downloadDone: '😉[br]We’ve just sent you an email to {email} with the instructions how to deploy Corezoid on {type}. ',
  downloadLink: 'You can also get them',
  downloadThank: '[b]Thank you for trusting us![/b] ',
  downloadHere: 'here',
  downloadBack: 'Done', // 'Back to the download options',

  // unsubscribe
  unsubscribeTitle: 'Let’s communicate better',
  unsubscribeText: 'The key to any great relationship is communication. So before you disconnect from all Corezoid correspondence and we never again have the chance to whisper our news and use cases into your inbox, please review your options.',
  formUnsubscribeTitle: 'Your current preferences:',
  formUnsubscribeReleaseNotes: 'Release notes',
  formUnsubscribeNews: 'News',
  formUnsubscribeUseCases: 'Case studies',
  formUnsubscribed: 'Do not send me any promotional email [br](you’ll still receive alerts and transactional email)',
  formSendUnsubscribe: 'Update my preferences',

  // form
  formSuccess: 'Appreciate you taking the time to apply.',
  formServerError: 'Server not respond',
  formSuccessMassage: 'Your message has been sent. We will contact you in 1 day.',
  formSuccessMassageUnsubscribe: 'Your subscription settings have been updated.',
  formSuccessMassageAutoSend: ' We’ll contact you shortly.',
  formLiveDemoTitle: 'Live Demo',
  formLiveDemoSubTitle: 'Would you like to see how Corezoid can help you?[br][br]Request a live demo and our expert will demonstrate the powerful features and capabilities of Corezoid and will be happy to answer your questions regarding our product.[br][br]All you need is an internet connection, PC or Mac, Skype, and 30 minutes of your time.',
  formLiveDemoContactUs: 'Contact Us',
  formLiveDemoButton: 'Request demo',
  formSupportSubTitle: 'Do you want to buy Corezoid and have questions about support?[br][br]Contact us directly![br][br][iurl=/pricing/public-cloud]Pricing[/iurl][br][iurl=/terms-and-conditions]Privacy Terms[/iurl][br][iurl=/form/live-demo]Live demo[/iurl]',
  vacancyTitle: 'Publish your job offers',
  vacancyText: 'Certified specialists will be able to see your proposal and submit their candidacy.',
  formFirstName: 'First name',
  formName: 'Your name',
  formLastName: 'Last name',
  formFullName: 'Full name',
  formCvLink: 'Link to CV',
  formJoinYourTeam: 'I want to work in your team',
  formPosition: 'Desired vacancy',
  formComments: 'Comments',
  formEmail: 'E-mail',
  formPhone: 'Phone Number',
  formCompany: 'Company',
  formCountry: 'Country',
  formChanel: 'Chanel',
  formChanelOther: 'other...',
  formTitle: 'Title',
  formCity: 'City',
  formDate: 'Date',
  formLevel: 'Level',
  formEmployees: 'How many employees?',
  formFormat: 'Format',
  formFormatOnline: 'online',
  formFormatOffline: 'offline',
  formInterested: 'I\'m interested in',
  formInterestedGeneral: 'General',
  formInterestedSales: 'Sales',
  formInterestedBilling: 'Billing',
  formInterestedMarketing: 'Marketing',
  formMessage: 'Your message',
  formSend: 'Send message',
  formSendVacancy: 'Send and go to the test!',
  formSendSubmitVacancy: 'Submit vacancy',
  formJobTitle: 'Job title',
  formProjectDescription: 'Project description',
  formRequirements: 'Requirements',
  formLastResponsibilities: 'Responsibilities',
  formBenefits: 'Benefits',
  formAdditionalInformation: 'Additional information',
  formResponsiblePerson: 'Responsible Person',
  formCOntactPerson: 'Contact person',

  // extended-trial
  extendedTrialTitle: 'Request extended trial',
  extendedTrialSubTitle: '30 days weren\'t enough to dive deep into Corezoid? No problem. Request to extend the trial period to get better understanding of Corezoid Actor Engine.',
  extendedTrialFormMessage: 'Please describe why do you need an extended trial',
  extendedTrialFormSubText: 'This information will be used to improve customer onboarding',
  extendedTrialFormButtonText: 'Request extended trial',

  // benefits
  benefitsManagersSpeed: 'Time-to-market speed',
  benefitsManagersSpeedText: 'With Corezoid you can assemble processes in minutes without developers via a drag&drop process editor.',
  benefitsManagersDashboards: 'Real-time dashboards',
  benefitsManagersDashboardsText: 'Using built-in real-time dashboards you can get access to valuable information about your business in 1 place.',
  benefitsManagersEsc: 'Real-time escalations',
  benefitsManagersEscText: 'Create custom real-time escalations and avoid silent failures and handle your business events.',
  benefitsManagersCurve: 'Easy Learning Curve',
  benefitsManagersCurveText: 'All you need to start assembling processes in Corezoid is to learn how to combine 15 different logic types with each other. Usually it takes up to 3 days.',
  benefitsManagersProto: 'Fast Prototyping',
  benefitsManagersProtoText: 'Minimize risks and fail faster to succeed sooner, Corezoid allows developing prototypes to test hypotheses and products in minutes with minimum efforts.',
  benefitsManagersBusiness: 'Business continuity',
  benefitsManagersBusinessText: 'Corezoid’s explicit nature gives your business an opportunity to minimize risks and increase resilence with little resources.',
  benefitsManagersEngagement: 'Engagement',
  benefitsManagersEngagementText: 'Low entry threshold and fast learning curve empower employees from any background to start using Corezoid and influence business.',
  benefitsManagersCollaboration: 'Collaboration',
  benefitsManagersCollaborationText: 'Built-in features for collaboration like process sharing and permissions management unlock unrealized value of cumulative creativity and experience.',
  benefitsManagersTransparency: 'Transparency',
  benefitsManagersTransparencyText: 'Corezoid collects all company’s processes in one place, so every team member can get access and manage any process according to the given permissions.',
  benefitsManagersSecurity: 'Security',
  benefitsManagersSecurityText: 'Corezoid was born within fintech, where security is crucial. We’ve been audited by the world’s leading companies like Western Union, Avon and METRO to match the highest security standards. Corezoid is PCI DSS and ISO 27001 compliant.',
  benefitsManagersHigh: 'High Performance',
  benefitsManagersHighText: 'Corezoid was built to be used for high-load projects and industries. It’s the only tool that allows you to build, host and run processes in real-time and handle thousands of concurrent requests with little resource involvement.',
  benefitsManagersUltra: 'Ultra Scalability',
  benefitsManagersUltraText: 'Corezoid architecture was built around ultra fast horizontal scalability for minimum efforts. You can separately scale each of Corezoid\'s component for efficiency or clusterize Corezoid instances for maximum performance.',
  benefitsDevelopersProcess: 'Process Editor',
  benefitsDevelopersProcessText: 'Intuitive drag&drop Corezoid Process Editor makes it possible to assemble algorithms of any complexity in minutes.',
  benefitsDevelopersHosting: 'Process Hosting & Execution',
  benefitsDevelopersHostingText: 'Corezoid Actor Engine allows you to create, deploy and execute processes in one place just in a few clicks.',
  benefitsDevelopersDebug: 'Process Debug',
  benefitsDevelopersDebugText: 'Corezoid Debug mode allows you monitoring each step of the process execution and easily fix possible bugs.',
  benefitsDevelopersDashboards: 'Real-time Dashboards',
  benefitsDevelopersDashboardsText: 'Corezoid’s built-in fully customizable real-time dashboards allows you feeling the heartbeat of your processes to make critical decisions in real-time.',
  benefitsDevelopersEscalations: 'Real-time Escalations',
  benefitsDevelopersEscalationsText: 'Corezoid gives you an ability to add tailored real-time escalation breakpoints on each step of the process to avoid silent failures and overloads.',
  benefitsDevelopersPopular: 'All Popular API Types',
  benefitsDevelopersPopularText: 'Corezoid supports all popular API formats: JSON, XML, NVP. It means that you can easily integrate your processes with 99% of third-party APIs.',
  benefitsDevelopersApi: 'Process as an API',
  benefitsDevelopersApiText: 'Every process in Corezoid can be used as an API, so once you’ve created a process, partners and customers will be able to call it via token-based auth or "direct URL".',
  benefitsDevelopersFrameworks: 'Process Frameworks',
  benefitsDevelopersFrameworksText: 'Each process or group of processes can be used as a framework, so you can re-use it in the different projects and won’t reinvent the wheel.',
  benefitsDevelopersErlang: 'GIT Call',
  benefitsDevelopersErlangText: 'If you feel lack of the native functions of the nodes, you always can use “Code” node to add your own code written via JavaScript or Erlang to get things done.',
  benefitsDevelopersEvent: 'Event-driven Architecture',
  benefitsDevelopersEventText: 'Underlying technologies like asynchronous queuing system, parallel task processing and webhooks open new opportunities for your business to think forward and solve tactical and strategic challenges.',
  benefitsDevelopersParallel: 'Parallel Development',
  benefitsDevelopersParallelText: 'Corezoid architecture makes it possible to separate development paths via decomposing large projects into a small well-managed components that consists of a set of processes. So you would be able to do more in less time.',
  benefitsDevelopersManagement: 'Permission Management',
  benefitsDevelopersManagementText: 'Corezoid has access management system that allows to control permissions on 3 different levels: view process, modify process and create tasks in the process. So each team member will be empowered the right permission to execute his tasks.',
  benefitsDevelopersSecurity: 'Security',
  benefitsDevelopersSecurityText: 'Corezoid was born within fintech, where security is crucial. We’ve been audited by the world’s leading companies like Western Union, Avon and METRO to match the highest security standards. Corezoid is PCI DSS and ISO 27001 compliant.',
  benefitsDevelopersHigh: 'High Performance',
  benefitsDevelopersHighText: 'Corezoid was built to be used for high-load projects and industries. It’s the only tool that allows you to build, host and run processes in real-time and handle thousands of concurrent requests with little resource involvement.',
  benefitsDevelopersUltra: 'Ultra Scalability',
  benefitsDevelopersUltraText: 'Corezoid architecture was built around ultra fast horizontal scalability for minimum efforts. You can separately scale each of Corezoid\'s component for efficiency or clusterize Corezoid instances for maximum performance.',

  // aws
  awsHeaderTitle: 'Benefits of working with AWS',
  awsHeaderText: 'AWS provides secure, agile and innovative environment for the deployment of Corezoid-based projects.',
  awsRoute53: 'Amazon Route53',
  awsRoute53Description: 'Transfer of domain names to [iurl=https://aws.amazon.com/ru/route53/]Amazon Route53[/iurl] allows to easily isolate and distribute the traffic of users from various countries via a Geolocation Routing Policy.',
  awsElasticLoadBalancing: 'Elastic Load Balancing',
  awsElasticLoadBalancingDescription: 'Corezoid uses [iurl=https://aws.amazon.com/elasticloadbalancing/]Elastic Load Balancing[/iurl], which automatically distributes incoming traffic between multiple [iurl=https://aws.amazon.com/ec2/]Amazon EC2 instances[/iurl].',
  awsCloudTrial: 'Amazon CloudTrail',
  awsCloudTrailDescription: '[iurl=https://aws.amazon.com/cloudtrail/]AWS CloudTrail[/iurl] and AWS Config help to quickly track any changes in the production environment and maintain a high level of security.',
  awsCloudFormation: 'AWS CloudFormation',
  awsCloudFormationDescription: 'Using [iurl=https://aws.amazon.com/cloudformation/]AWS CloudFormation[/iurl], we significantly reduce the operating costs for infrastructure management, as all the key elements are defined as code. Developers can now run dedicated clusters to test the new features.',
  awsAutoScaling: 'AWS Auto Scaling',
  awsAutoScalingDescription: 'Using [iurl=https://aws.amazon.com/autoscaling/]Auto Scaling[/iurl], we were able to improve the quality and profitability of individual components, such as custom code execution service (Erlang, JS) in the individual process clusters, while paying only for the computing resources and storage resources that they utilize.',

  // footer
  footerStarted: 'GET STARTED',
  footerStartedContext: 'Learn how to use Corezoid with our free tutorials, dozens of sample processes and documentation.',
  footerFaq: 'FAQ',
  footerTutorials: 'Tutorials',
  footerDocumentation: 'Documentation',
  footerSampleProcesses: 'Sample Processes',
  footerDemo: 'REQUEST A LIVE DEMO',
  footerDemoContext: 'Request a live demo and let us show you the power of Corezoid Actor Engine! All you need is an internet connection and 30 minutes of free time.',
  footerContact: 'CONTACT US',
  footerContactContext: 'Contact us and we’ll be glad to answer all your questions and provide you with additional information about Corezoid.',

  footerLinkCorezoidProcesEngine: 'Corezoid Actor Engine',
  footerLinkPricing: 'Pricing',
  footerLinkResources: 'RESOURCES',
  footerLinkQuickStart: 'Quick start',
  footerLinkTutorials: 'Tutorials',
  footerLinkFaq: 'FAQ',
  footerLinkCommunity: 'Community',
  footerLinkWebinars: 'Webinars',
  footerLinkDocs: 'Documentation',
  footerLinkWorkshops: 'Workshops',
  footerLinkCertification: 'Certification',
  footerLinkDocumentation: 'Documentation',
  footerLinkReleaseNotes: 'Release Notes',
  footerLinkCompany: 'COMPANY',
  footerLinkAbout: 'About',
  footerLinkBlog: 'News',
  footerLinkJobs: 'Jobs',
  footerLinkMedia: 'Media',
  footerLinkResource: 'Press',
  footerLinkPrivacyTerms: 'Terms of Service',
  footerLinkContactUs: 'Contact us',
  footerLinkDownload: 'DOWNLOAD',
  footerLinkPresentations: 'Presentation',
  footerLinkWhitepapers: 'White paper',
  footerLinkVMware: 'Corezoid Instance',
  footerLinkMarketingKit: 'Marketing Kit',
  footerLinkSubscribe: 'SUBSCRIBE TO OUR NEWSLETTER',
  footerTextSubscribe: 'The latest Corezoid news, articles, and resources, sent straight to your inbox.',
  footerInputSubscribe: 'Your email address',
  footerButtonSubscribe: 'Subscribe',
  footerDoneSubscribe: 'Thank you!',
  langen: 'English',
  langru: 'Русский',
};
